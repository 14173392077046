// Profile.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import "./styles/Profile.css";
import { AuthContext } from '../AuthContext';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const Profile = () => {
  const [profile, setProfile] = useState({ username: '', role_id: '' });
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');

  // Fetch logged-in username from localStorage (or wherever you store it)
  const {user} = useContext(AuthContext);
  const loggedInUsername = user.username;

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.post(`${BASE_URL}user-profile/profile`, { username: loggedInUsername });
        setProfile(response.data);
        setNewUsername(response.data.username);
      } catch (error) {
        setMessage('Error fetching profile');
      }
    };

    fetchProfile();
  }, [loggedInUsername]);

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${BASE_URL}user-profile/profile`,
        { username: loggedInUsername, newUsername, newPassword }
      );
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error updating profile');
    }
  };

  return (
    <div className="profile-container">
      <h1>Profile</h1>
      <form onSubmit={handleUpdateProfile}>
        <div className="profile-input-group">
          <label htmlFor="newUsername">Username:</label>
          <input
            type="text"
            id="newUsername"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
            required
          />
        </div>
        <div className="profile-input-group">
          <label htmlFor="newPassword">Password:</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Leave blank to keep current password"
          />
        </div>
        <button type="submit" className="profile-submit-button">Update Profile</button>
      </form>
      {message && <p className='profile-message'>{message}</p>}
<div className="profile-details">
      <h2>Profile Details</h2>
      <p>Username: {profile.username}</p><br />
      <p>Role ID: {profile.role_id}</p>
      </div>
    </div>
  );
};

export default Profile;
