import React, { useState } from 'react';
import './styles/CompanyReport.css';

const CompanyReport = () => {
  const [year, setYear] = useState('');
  const [dateFilter, setDateFilter] = useState('equal');
  const [date, setDate] = useState('');
  const [partyName, setPartyName] = useState('');
  const [ref, setRef] = useState('');

  const handleSearch = () => {
    // Handle search action
    console.log('Searching with:', { year, dateFilter, date, partyName, ref });
  };

  const handleGetHawbNo = () => {
    // Handle getting HAWBNO
    console.log('Getting HAWBNO with:', { year, dateFilter, date, partyName, ref });
  };

  const handleDownload = () => {
    // Handle Excel download
    console.log('Downloading Excel with:', { year, dateFilter, date, partyName, ref });
  };

  return (
    <div className="company-report">
      <h1>Company Report</h1>
      <div className="company-report-form">
        <div className="company-report-form-group">
          <label>Year</label>
          <select
            value={year}
            onChange={(e) => setYear(e.target.value)}
          >
            <option value="">Select Year</option>
            <option value="2021-2022">2021-2022</option>
            <option value="2022-2023">2022-2023</option>
            {/* Add more year options as needed */}
          </select>
        </div>
        <div className="company-report-form-group">
          <label>Date</label>
          <select
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
          >
            <option value="before">Before</option>
            <option value="equal">Equal</option>
            <option value="after">After</option>
          </select>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="company-report-form-group">
          <label>Party Name</label>
          <input
            type="text"
            value={partyName}
            onChange={(e) => setPartyName(e.target.value)}
          />
        </div>
        <div className="company-report-form-group">
          <label>Ref#</label>
          <input
            type="text"
            value={ref}
            onChange={(e) => setRef(e.target.value)}
          />
        </div>
        <div className="company-report-button-group">
          <button onClick={handleSearch}>Search</button>
          <button onClick={handleGetHawbNo}>GET HAWBNO</button>
          <button onClick={handleDownload}>Download Excel</button>
        </div>
      </div>
    </div>
  );
};

export default CompanyReport;
