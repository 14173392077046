import React, { useState } from 'react';
import './styles/ServiceTaxReport.css';

const ServiceTaxReport = () => {
  const [yearFrom, setYearFrom] = useState('');
  const [yearTo, setYearTo] = useState('');
  const [dateFilter, setDateFilter] = useState('equal');
  const [date, setDate] = useState('');
  const [party, setParty] = useState('');
  const [refNumber, setRefNumber] = useState('');
  const [summary, setSummary] = useState({
    totalServiceAmount: 0,
    serviceTaxAmount: 0,
    transportAmount: 0,
    totalRecords: 0
  });

  const handleClick = () => {
    // Fetch and calculate summary data
    console.log('Fetching data with:', { yearFrom, yearTo, dateFilter, date, party, refNumber });
    // Simulate data fetching and setting the summary
    setSummary({
      totalServiceAmount: 5000,
      serviceTaxAmount: 700,
      transportAmount: 300,
      transportTaxAmount: 200,
      totalRecords: 50
    });
  };

  const handleDownload = () => {
    // Handle Excel download
    console.log('Downloading Excel with:', { yearFrom, yearTo, dateFilter, date, party, refNumber });
  };

  return (
    <div className="service-tax-report">
      <h1>Service Tax Report</h1>
      <div className="service-tax-report-form">
        <div className="service-tax-report-form-group">
          <label>Year</label>
          <input
            type="text"
            placeholder="From"
            value={yearFrom}
            onChange={(e) => setYearFrom(e.target.value)}
          />
          <input
            type="text"
            placeholder="To"
            value={yearTo}
            onChange={(e) => setYearTo(e.target.value)}
          />
        </div>
        <div className="service-tax-report-form-group">
          <label>Date</label>
          <select
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
          >
            <option value="before">Before</option>
            <option value="equal">Equal</option>
            <option value="after">After</option>
          </select>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="service-tax-report-form-group">
          <label>Party</label>
          <input
            type="text"
            value={party}
            onChange={(e) => setParty(e.target.value)}
          />
        </div>
        <div className="service-tax-report-form-group">
          <label>Ref#</label>
          <input
            type="text"
            value={refNumber}
            onChange={(e) => setRefNumber(e.target.value)}
          />
        </div>
        <div className="service-tax-report-button-group">
          <button onClick={handleClick}>Click</button>
        </div>
      </div>
      <div className="service-tax-report-summary">
        <h2>Summary</h2>
        <p>Total Service Amount: {summary.totalServiceAmount}</p>
        <p>Service Tax Amount: {summary.serviceTaxAmount}</p>
        <p>Transport Amount: {summary.transportAmount}</p>
        <p>Transport Tax Amount: {summary.transportTaxAmount}</p>
        <p>Total No. of Records: {summary.totalRecords}</p>
        <button onClick={handleDownload}>Download Excel</button>
      </div>
    </div>
  );
};

export default ServiceTaxReport;
