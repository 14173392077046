import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles/ManageUsers.css';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const ManageUsers = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState('');
  const [message, setMessage] = useState('');
  const [users, setUsers] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [newRoleId, setNewRoleId] = useState('');

  useEffect(() => {
    const fetchRoles = async () => {
      const response = await axios.get(BASE_URL + '/roles');
      setRoles(response.data);
    };
    
    const fetchUsers = async () => {
      const response = await axios.get(BASE_URL + '/users'); // Adjust endpoint as necessary
      setUsers(response.data);
    };

    fetchRoles();
    fetchUsers();
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const endpoint = editingUserId ? `${BASE_URL}/users/${editingUserId}` : `${BASE_URL}/register`;
      const method = editingUserId ? 'put' : 'post';
      const response = await axios[method](endpoint, { username, password, roleId });
      
      setMessage(response.data.message);
      setUsername('');
      setPassword('');
      setRoleId('');
      setEditingUserId(null);
      // Refetch users after registration or editing
      const updatedUsers = await axios.get(BASE_URL + '/users'); // Adjust endpoint as necessary
      setUsers(updatedUsers.data);
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message);
      } else {
        setMessage('An error occurred. Please try again.');
      }
    }
  };

  const handleEditUser = async (user) => {
    const newUsername = prompt("Enter new username:", user.username);
    const newPassword = prompt("Enter new password:", "");
  
    if (newUsername !== null) { // Check if prompt was canceled
      try {
        await axios.put(`${BASE_URL}/update-user`, {
          userId: user.id,
          username: newUsername,
          password: newPassword !== null ? newPassword : user.password, 
        });
        setMessage('User updated successfully');
        // Re-fetch users
        const updatedUsers = await axios.get(BASE_URL + '/users');
        setUsers(updatedUsers.data);
      } catch (error) {
        setMessage('Failed to update user. Please try again.');
      }
    }
  };

  const handleOpenRoleModal = (userId) => {
    setSelectedUserId(userId);
    setIsRoleModalOpen(true);
  };

  const handleChangeRole = async () => {
    if (newRoleId) {
      try {
        const response = await axios.put(`${BASE_URL}/update-role`, { userId: selectedUserId, roleId: newRoleId });
        setMessage(response.data.message);
        setIsRoleModalOpen(false);
        setNewRoleId('');
        // Refetch users after role change
        const updatedUsers = await axios.get(BASE_URL + '/users');
        setUsers(updatedUsers.data);
      } catch (error) {
        setMessage('Failed to update role. Please try again.');
      }
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await axios.delete(`${BASE_URL}/delete-user/${userId}`);
        setMessage('User deleted successfully');
        // Re-fetch users
        const updatedUsers = await axios.get(BASE_URL + '/users');
        setUsers(updatedUsers.data);
      } catch (error) {
        setMessage('Failed to delete user. Please try again.');
      }
    }
  };

  return (
    <div className="manage-users">
      <h1>Manage Users</h1>
      <form onSubmit={handleRegister} className="register-form">
        <div className="manage-users-form-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="manage-users-form-input"
          />
        </div>
        <div className="manage-users-form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required={!editingUserId} // Password is required only for new users
            className="manage-users-form-input"
          />
        </div>
        <div className="manage-users-form-group">
          <label htmlFor="role">Role:</label>
          <select
            id="role"
            value={roleId}
            onChange={(e) => setRoleId(e.target.value)}
            required
            className="manage-users-form-input"
          >
            <option value="">Select Role</option>
            {roles.map((role) => (
              <option key={role.id} value={role.id}>
                {role.role_name}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="manage-users-submit-button">
          {editingUserId ? 'Update User' : 'Register'}
        </button>
      </form>
      {message && <p className="message">{message}</p>}
      <h2>Existing Users</h2>
      <table className="users-table">
        <thead>
          <tr>
            <th>Username</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.username}</td>
              <td>
                <button onClick={() => handleEditUser(user)}>Edit Username/Password</button>
                <button onClick={() => handleDeleteUser(user.id)}>Delete</button>
                <button onClick={() => handleOpenRoleModal(user.id)}>Change Role</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for Changing Role */}
      {isRoleModalOpen && (
        <div className="modal">
          <h2>Select New Role</h2>
          <select value={newRoleId} onChange={(e) => setNewRoleId(e.target.value)}>
            <option value="">Select Role</option>
            {roles.map((role) => (
              <option key={role.id} value={role.id}>
                {role.role_name}
              </option>
            ))}
          </select>
          <button onClick={handleChangeRole}>Change Role</button>
          <button onClick={() => setIsRoleModalOpen(false)}>Cancel</button>
        </div>
      )}
    </div>
  );
};

export default ManageUsers;
