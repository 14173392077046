import React, { useState } from 'react';
import './styles/BondHistoryDetailsReport.css';

const BondHistoryDetailsReport = () => {
  const [impexNo, setImpexNo] = useState('');
  const [inBondBENo, setInBondBENo] = useState('');

  const handleShowDetails = () => {
    // Fetch and display bond history details based on the IMPEX No and IN Bond BE No
    console.log('Show details for:', { impexNo, inBondBENo });
  };

  return (
    <div className="bond-history-details-report">
      <h1>Bond History Details Report</h1>
      <div className="bond-history-form">
        <div className="bond-history-form-group">
          <label>IMPEX NO.</label>
          <input
            type="text"
            value={impexNo}
            onChange={(e) => setImpexNo(e.target.value)}
          />
        </div>
        <div className="bond-history-form-group">
          <label>IN Bond BE No</label>
          <input
            type="text"
            value={inBondBENo}
            onChange={(e) => setInBondBENo(e.target.value)}
          />
        </div>
        <div className="bond-history-button-group">
          <button onClick={handleShowDetails}>Show Details</button>
        </div>
      </div>
    </div>
  );
};

export default BondHistoryDetailsReport;
