import React, { useState } from 'react';
import './styles/ViewLog.css';

const ViewLog = () => {
  const [dateCondition, setDateCondition] = useState('before');
  const [date, setDate] = useState('');
  const [userId, setUserId] = useState('');
  const [operation, setOperation] = useState('');

  const handleViewClick = () => {
    // Handle view button click logic here
    console.log('Viewing logs...');
  };

  return (
    <div className="view-log">
      <h1>View Log</h1>
      <form className="view-log-form">
        <div className="view-log-form-row">
          <div className="view-log-form-group">
            <label>Date:</label>
            <select
              value={dateCondition}
              onChange={(e) => setDateCondition(e.target.value)}
            >
              <option value="before">Before</option>
              <option value="equal">Equal</option>
              <option value="after">After</option>
            </select>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div className="view-log-form-group">
            <label>User ID:</label>
            <input
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
          </div>
          <div className="view-log-form-group">
            <label>Operation:</label>
            <input
              type="text"
              value={operation}
              onChange={(e) => setOperation(e.target.value)}
            />
          </div>
        </div>
        <div className="view-log-form-group">
          <button type="button" onClick={handleViewClick}>View</button>
        </div>
      </form>
    </div>
  );
};

export default ViewLog;
