import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './styles/BillTax.css';
import { AuthContext } from '../AuthContext';

const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'bill-tax';

const BillTax = () => {
  const [billTax, setBillTax] = useState('');
  const [taxes, setTaxes] = useState([]); 
  const [editId, setEditId] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { user } = useContext(AuthContext);
  const username = user.username;

  useEffect(() => {
    fetchTaxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTaxes = async () => {
    try {
      const response = await axios.get(BASE_URL);
      setTaxes(response.data);
      if (response.data.length > 0) {
        // If there's already a saved record, set it to edit mode
        const taxRecord = response.data[0];
        setBillTax(taxRecord.bill_tax);
        setEditId(taxRecord.id);
      }
    } catch (err) {
      console.error('Error fetching bill taxes:', err);
      setError('Error fetching bill taxes');
    }
  };

  const handleSave = async () => {
    if (!billTax) {
      setError('Bill Tax is required');
      return;
    }

    try {
      if (editId) {
        await axios.put(`${BASE_URL}/${editId}`, { billTax, updatedBy: username });
        setSuccessMessage('Updated successfully');
      } else {
        await axios.post(BASE_URL, { billTax, createdBy: username });
        setSuccessMessage('Saved successfully');
      }
      fetchTaxes();
    } catch (err) {
      console.error('Error saving bill tax:', err);
      setError('Error saving bill tax');
    }
  };

  return (
    <div className="bill-tax">
      <h1 className="bill-tax-title">Bill Tax Management</h1>
      <div className="bill-tax-form">
        <label className="bill-tax-label">Bill Tax</label>
        <input
          type="text"
          className="bill-tax-input"
          value={billTax}
          onChange={(e) => setBillTax(e.target.value)}
          disabled={taxes.length > 0 && !editId}  
        />
        <div className="bill-tax-button-group">
          <button
            className="bill-tax-button save"
            onClick={handleSave}
            disabled={taxes.length > 0 && !editId}  
          >
            {editId ? 'Update' : 'Save'}
          </button>
        </div>
        {error && <p className="bill-tax-message error">{error}</p>}
        {successMessage && <p className="bill-tax-message success">{successMessage}</p>}
      </div>

      {taxes.length > 0 && (
        <table className="bill-tax-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Bill Tax</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {taxes.map((tax) => (
              <tr key={tax.id}>
                <td>{tax.id}</td>
                <td>{tax.bill_tax}</td>
                <td>
                  <button className="bill-tax-button edit" onClick={() => setEditId(tax.id)}>
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default BillTax;
