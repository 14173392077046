import React, { useState, useEffect } from 'react';
import './styles/IncompleteJobs.css';
import axios from 'axios';

const IncompleteJobs = () => {
  const [dateCondition, setDateCondition] = useState('equal'); 
  const [date, setDate] = useState(''); 
  const [allJobs, setAllJobs] = useState([]); 
  const [filteredJobs, setFilteredJobs] = useState([]); 

  useEffect(() => {
    // Fetch all data once on component mount
    const fetchAllJobs = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'incomplete-jobs/getIncompleteJobs');
        setAllJobs(response.data);
        setFilteredJobs(response.data); // Initially display all jobs
      } catch (error) {
        console.error('Error fetching all jobs:', error);
      }
    };

    fetchAllJobs();
  }, []);

  const handleFilter = (e) => {
    e.preventDefault();
    let filtered = [...allJobs]; // Create a copy of all jobs

    if (date) {
      const filterDate = new Date(date);

      filtered = filtered.filter(job => {
        const jobDate = new Date(job.date);

        switch (dateCondition) {
          case 'equal':
            return jobDate.toDateString() === filterDate.toDateString();
          case 'before':
            return jobDate < filterDate;
          case 'after':
            return jobDate > filterDate;
          default:
            return false;
        }
      });
    }

    setFilteredJobs(filtered);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
    <div className="incomplete-jobs-container">
    <h1 className="incomplete-jobs-title">Incomplete Jobs</h1>
    <div className="incomplete-jobs-form">
      <form className="incomplete-jobs-form-group">
        <label className="incomplete-jobs-label" htmlFor="dateCondition">Date Condition:</label>
        <select 
          id="dateCondition" 
          className="incomplete-jobs-dropdown" 
          value={dateCondition} 
          onChange={(e) => setDateCondition(e.target.value)}
        >
          <option value="equal">Equal</option>
          <option value="before">Before</option>
          <option value="after">After</option>
        </select>
        <label className="incomplete-jobs-label" htmlFor="date">Date:</label>
        <input 
          type="date" 
          id="date" 
          className="incomplete-jobs-input" 
          value={date} 
          onChange={(e) => setDate(e.target.value)} 
        />
        <div className="incomplete-jobs-button-group">
          <button 
            className="incomplete-jobs-button incomplete-jobs-button-show" 
            onClick={handleFilter}
          >
            Filter
          </button>
        </div>
      </form>
    </div>
  
    {filteredJobs.length > 0 ? (
      <table className="incomplete-jobs-table">
        <thead>
          <tr>
            <th>Service Type</th>
            <th>Transport Mode</th>
            <th>Bill Type</th>
            <th>Reference No</th>
            <th>Date</th>
            <th>Party</th>
          </tr>
        </thead>
        <tbody>
          {filteredJobs.map((job) => (
            <tr key={job.ref_no}>
              <td>{job.service_type}</td>
              <td>{job.trans_mode}</td>
              <td>{job.be_type}</td>
              <td>{job.ref_no}</td>
              <td>{formatTimestamp(job.date)}</td>
              <td>{job.importer_name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <p className="incomplete-jobs-message">No incomplete jobs found based on the selected criteria.</p>
    )}
  </div>
  );
};

export default IncompleteJobs;
