import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles/ExportJobRegistration.css';
import useFetch from '../utils/useFetch';

const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'import-job';

const JobUpdation = () => {
    const { currencies, errors } = useFetch();
    console.log(currencies, "hi");
    const [formData, setFormData] = useState({
        date: '',
        service_type: 'Imports',
        import_no: '',
        job_id: '',
        job_date: '',
        trans_mode: 'Sea',
        branch: 'Chennai',
        be_type: 'SEZ',
        importer_name: '',
        consignor_name: '',
        ref_no: '',
        country_of_shipment: '',
        port_of_shipment: '',
        mother_vessel_name: '',
        v_no: '',
        eta: '',
        remainder: false,
        feeder_vessel_name: '',
        feeder_v_no: '',
        feeder_eta: '',
        forwarder: '',
        shipping_line: '',
        rotation: '',
        rotation_date: '',
        line_no: '',
        mbl_no: '',
        mbl_date: '',
        hbl_no: '',
        hbl_date: '',
        cbm: 0,
        cfs: '',
        net_wt: 0,
        gross_wt: 0,
        num_of_pkgs: 0,
        container_no: '',
        stc: '',
        stc2: '',
        size_20: 0,
        size_40: 0,
        total_container: 0,
        remarks: '',
        invoice_details: [],
        freight_details: [],
    });

    const [isInvoicePopupOpen, setIsInvoicePopupOpen] = useState(false);
    const [isFreightPopupOpen, setIsFreightPopupOpen] = useState(false);
    const [invoiceData, setInvoiceData] = useState({
        terms: 'TRANSFER',
        currency: '',
        invoice: '',
        invoice_date: '',
        po: '',
        po_date: '',
        description: '',
        qty: 0,
        unit: 'kgs',
        unit_price: 0,
        value: 0,
    });

    const [freightData, setFreightData] = useState({
        freight: 0,
        insurance: 0,
        other_charges: 0,
        fob: 0,
    });

    const handleInvoicePopupSave = () => {
        setFormData(prevData => ({
            ...prevData,
            invoice_details: [...prevData.invoice_details, invoiceData],
        }));
        setIsInvoicePopupOpen(false);
        setInvoiceData({
            terms: 'TRANSFER',
            currency: '',
            invoice: '',
            invoice_date: '',
            po: '',
            po_date: '',
            description: '',
            qty: 0,
            unit: 'kgs',
            unit_price: 0,
            value: 0,
        });
    };

    const handleFreightPopupSave = () => {
        setFormData(prevData => ({
            ...prevData,
            freight_details: [...prevData.freight_details, freightData],
        }));
        setIsFreightPopupOpen(false);
        setFreightData({
            freight: 0,
            insurance: 0,
            other_charges: 0,
            fob: 0,
        });
    };

    const [lastRecordId, setLastRecordId] = useState(0);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchLastRecordId = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/last-record-id`);
                setLastRecordId(response.data.lastRecordId || 0);
            } catch (error) {
                console.error('Error fetching last record ID:', error);
            }
        };

        fetchLastRecordId();
    }, []);

    const generateRefNo = () => {
        const year = new Date().getFullYear();
        const yearSuffix = year % 100;
        const serviceTypeInitial = formData.service_type[0].toUpperCase();
        const transModeInitial = formData.trans_mode[0].toUpperCase();
        const nameInitial = formData.service_type === 'Imports'
            ? formData.importer_name[0].toUpperCase()
            : formData.consignor_name[0].toUpperCase();
        const recordNumber = String(lastRecordId + 1).padStart(5, '0');

        const refNo = `${yearSuffix}${serviceTypeInitial}${transModeInitial}${nameInitial}${recordNumber}`;
        setFormData(prevData => ({
            ...prevData,
            ref_no: refNo
        }));
    };

    const handleGetRefNo = () => {
        generateRefNo();
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleInvoiceChange = (e) => {
        const { name, value } = e.target;
        setInvoiceData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleFreightChange = (e) => {
        const { name, value } = e.target;
        setFreightData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const getLabel = (label) => {
        if (formData.service_type === 'Exports') {
            switch (label) {
                case 'Import No': return 'Export No';
                case 'B/E Type': return 'S/B Type';
                case 'Importer Name': return 'Exporter Name';
                case 'Consignor Name': return 'Consignee Name';
                default: return label;
            }
        }
        return label;
    };

    const handleSave = async (event) => {
        event.preventDefault();
        try {
            await axios.post(BASE_URL, formData);
            setSuccessMessage('Job registered successfully');
            setErrorMessage('');
            resetForm();
        } catch (err) {
            console.error('Error saving job:', err);
            setErrorMessage('Error saving job');
            setSuccessMessage('');
        }
    };

    const resetForm = () => {
        setFormData({
            date: '',
            service_type: 'Imports',
            import_no: '',
            job_id: '',
            job_date: '',
            trans_mode: 'Sea',
            branch: 'Chennai',
            be_type: 'SEZ',
            importer_name: '',
            consignor_name: '',
            ref_no: '',
            country_of_shipment: '',
            port_of_shipment: '',
            mother_vessel_name: '',
            v_no: '',
            eta: '',
            remainder: false,
            feeder_vessel_name: '',
            feeder_v_no: '',
            feeder_eta: '',
            forwarder: '',
            shipping_line: '',
            rotation: '',
            rotation_date: '',
            line_no: '',
            mbl_no: '',
            mbl_date: '',
            hbl_no: '',
            hbl_date: '',
            cbm: 0,
            cfs: '',
            net_wt: 0,
            gross_wt: 0,
            num_of_pkgs: 0,
            container_no: '',
            stc: '',
            stc2: '',
            size_20: 0,
            size_40: 0,
            total_container: 0,
            remarks: '',
            invoice_details: [],
            freight_details: [],
        });
        setLastRecordId(lastRecordId + 1);
    };

    return (
        <div className="export-job-registration">
            <form onSubmit={handleSave}>
                <div>
                    <div className="row-title">Job Registration</div>
                </div>
                <div className="row">
                    <label>Date: <input type="date" name="date" value={formData.date} onChange={handleChange} required /></label>
                    <label>Service Type:
                        <select name="service_type" value={formData.service_type} onChange={handleChange}>
                            <option value="Imports">Imports</option>
                            <option value="Exports">Exports</option>
                        </select>
                    </label>
                    <label>{getLabel('Imp/Exp No.')}: <input type="text" name="import_no" value={formData.import_no} onChange={handleChange} /></label>
                    <label>Job ID: <input type="text" name="job_id" value={formData.job_id} onChange={handleChange} /></label>
                </div>

                <div className="row">
                    <label>Job Date: <input type="date" name="job_date" value={formData.job_date} onChange={handleChange} /></label>
                    <label>Trans Mode:
                        <select name="trans_mode" value={formData.trans_mode} onChange={handleChange}>
                            <option value="Sea">Sea</option>
                            <option value="Air">Air</option>
                        </select>
                    </label>
                    <label>Branch: <input type="text" name="branch" value={formData.branch} onChange={handleChange} /></label>
                    <label>{getLabel('B/E Type')}: <input type="text" name="be_type" value={formData.be_type} onChange={handleChange} /></label>
                </div>

                <div className="row">
                    <label>{getLabel('Importer Name')}: <input type="text" name="importer_name" value={formData.importer_name} onChange={handleChange} /></label>
                    <label>{getLabel('Consignor Name')}: <input type="text" name="consignor_name" value={formData.consignor_name} onChange={handleChange} /></label>
                    <label>Ref No.:
                        <div className="ref-no-group">
                            <input type="text" name="ref_no" value={formData.ref_no} readOnly />
                            <button type="button" onClick={handleGetRefNo}>Get Ref No.</button>
                        </div>
                    </label>
                    <div className="row-title">Shipping Details</div>
                </div>

                <div className="row">
                    <label>Country of Shipment: <input type="text" name="country_of_shipment" value={formData.country_of_shipment} onChange={handleChange} /></label>
                    <label>Port of Shipment: <input type="text" name="port_of_shipment" value={formData.port_of_shipment} onChange={handleChange} /></label>
                </div>
                <div className="row">
                    <label>Mother Vessel Name: <input type="text" name="mother_vessel_name" value={formData.mother_vessel_name} onChange={handleChange} /></label>
                    <label>Mother Vessel No: <input type="text" name="v_no" value={formData.v_no} onChange={handleChange} /></label>
                    <label>ETA: <input type="date" name="eta" value={formData.eta} onChange={handleChange} /></label>
                </div>

                <div className="row">
                    <label>Feeder Vessel Name: <input type="text" name="feeder_vessel_name" value={formData.feeder_vessel_name} onChange={handleChange} /></label>
                    <label>Feeder Vessel No:
                        <input type="text" name="feeder_v_no" value={formData.feeder_v_no} onChange={handleChange} /></label>
                    <label>ETA: <input type="date" name="feeder_eta" value={formData.feeder_eta} onChange={handleChange} /></label>
                </div>
                <div className="row">
                    <label>Forwarder: <input type="text" name="forwarder" value={formData.forwarder} onChange={handleChange} /></label>
                    <label>Shipping Line: <input type="text" name="shipping_line" value={formData.shipping_line} onChange={handleChange} /></label>
                </div>

                <div className="row">
                    <label>Rotation No.: <input type="text" name="rotation" value={formData.rotation} onChange={handleChange} /></label>
                    <label>Rotation Date: <input type="date" name="rotation_date" value={formData.rotation_date} onChange={handleChange} /></label>
                    <label>Line No.: <input type="text" name="line_no" value={formData.line_no} onChange={handleChange} /></label>
                </div>

                <div className="row">
                    <label>MBL No.: <input type="text" name="mbl_no" value={formData.mbl_no} onChange={handleChange} /></label>
                    <label>MBL Date: <input type="date" name="mbl_date" value={formData.mbl_date} onChange={handleChange} /></label>
                    <label>HBL No.: <input type="text" name="hbl_no" value={formData.hbl_no} onChange={handleChange} /></label>
                    <label>HBL Date: <input type="date" name="hbl_date" value={formData.hbl_date} onChange={handleChange} /></label>
                    <label>CBM: <input type="number" name="cbm" value={formData.cbm} onChange={handleChange} /></label>
                    <label>CFS: <input type="text" name="cfs" value={formData.cfs} onChange={handleChange} /></label>
                </div>

                <div className="row">
                    <label>Net Weight (kg): <input type="number" name="net_wt" value={formData.net_wt} onChange={handleChange} /></label>
                    <label>Gross Weight (kg): <input type="number" name="gross_wt" value={formData.gross_wt} onChange={handleChange} /></label>
                    <label>Number of Packages: <input type="number" name="num_of_pkgs" value={formData.num_of_pkgs} onChange={handleChange} /></label>
                </div>

                <div className="row">
                    <label>Container No.: <input type="text" name="container_no" value={formData.container_no} onChange={handleChange} /></label>
                    <label>STC: <input type="text" name="stc" value={formData.stc} onChange={handleChange} /></label>
                    <label>STC2: <input type="text" name="stc2" value={formData.stc2} onChange={handleChange} /></label>
                </div>

                <div className="row">
                    <label>Size 20': <input type="number" name="size_20" value={formData.size_20} onChange={handleChange} /></label>
                    <label>Size 40': <input type="number" name="size_40" value={formData.size_40} onChange={handleChange} /></label>
                </div>

                <div className="row">
                    <label>Total Containers: <input type="number" name="total_container" value={formData.total_container} onChange={handleChange} /></label>
                    <label>Remainder:
                        <input type="checkbox" name="remainder" checked={formData.remainder} onChange={handleChange} />
                    </label>
                </div>

                <div className="row">
                    <label>Remarks: <input type="text" name="remarks" value={formData.remarks} onChange={handleChange} /></label>
                    <label>Invoice Details:
                        <button type="button" onClick={() => setIsInvoicePopupOpen(true)}>Invoice Details</button>
                    </label>
                    {/* Invoice Details Popup */}
                    {isInvoicePopupOpen && (
                        <div className="popup">
                            <div className="popup-inner">
                                <h3>Invoice Details</h3>
                                <label>Terms:
                                    <select name="terms" value={invoiceData.terms} onChange={handleInvoiceChange}>
                                        <option value="TRANSFER">TRANSFER</option>
                                        <option value="SEZ">SEZ</option>
                                    </select>
                                </label>
                                <label>Currency: <input type="text" name="currency" value={invoiceData.currency} onChange={handleInvoiceChange} /></label>
                                <label>Invoice: <input type="text" name="invoice" value={invoiceData.invoice} onChange={handleInvoiceChange} /></label>
                                <label>Invoice Date: <input type="date" name="invoice_date" value={invoiceData.invoice_date} onChange={handleInvoiceChange} /></label>
                                <label>PO: <input type="text" name="po" value={invoiceData.po} onChange={handleInvoiceChange} /></label>
                                <label>PO Date: <input type="date" name="po_date" value={invoiceData.po_date} onChange={handleInvoiceChange} /></label>
                                <label>Description: <input type="text" name="description" value={invoiceData.description} onChange={handleInvoiceChange} /></label>
                                <label>Qty: <input type="number" name="qty" value={invoiceData.qty} onChange={handleInvoiceChange} /></label>
                                <label>Unit:
                                    <select name="unit" value={invoiceData.unit} onChange={handleInvoiceChange}>
                                        <option value="kgs">Kgs</option>
                                        <option value="ltrs">Ltrs</option>
                                    </select>
                                </label>
                                <label>Unit Price: <input type="number" name="unit_price" value={invoiceData.unit_price} onChange={handleInvoiceChange} /></label>
                                <label>Value: <input type="number" name="value" value={invoiceData.value} onChange={handleInvoiceChange} /></label>
                                <button type="button" onClick={handleInvoicePopupSave}>Save</button>
                                <button type="button" onClick={() => setIsInvoicePopupOpen(false)}>Cancel</button>
                            </div>
                        </div>
                    )}
                    <label>Freight Details:
                        <button type="button" onClick={() => setIsFreightPopupOpen(true)}>Freight Details</button>
                    </label>
                    {/* Freight Details Popup */}
                    {isFreightPopupOpen && (
                        <div className="popup">
                            <div className="popup-inner">
                                <h3>Freight Details</h3>
                                <label>Freight: <input type="number" name="freight" value={freightData.freight} onChange={handleFreightChange} /></label>
                                <label>Insurance: <input type="number" name="insurance" value={freightData.insurance} onChange={handleFreightChange} /></label>
                                <label>Other Charges: <input type="number" name="other_charges" value={freightData.other_charges} onChange={handleFreightChange} /></label>
                                <label>FOB: <input type="number" name="fob" value={freightData.fob} onChange={handleFreightChange} /></label>
                                <button type="button" onClick={handleFreightPopupSave}>Save</button>
                                <button type="button" onClick={() => setIsFreightPopupOpen(false)}>Cancel</button>
                            </div>
                        </div>
                    )}
                </div>

                <div className="row">
                    {successMessage && <p className="success-message">{successMessage}</p>}
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                </div>

                <div className="row">
                    <button type="submit" className="save-button">Save</button>
                    <button type="button" className="reset-button" onClick={resetForm}>Reset</button>
                </div>
            </form>
        </div>
    );
};

export default JobUpdation;

