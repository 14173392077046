import React, { useState, useEffect, useCallback, useContext } from 'react';
import './styles/GSTPrintAndApprove.css';
import { jsPDF } from 'jspdf';
import axios from 'axios';
import { AuthContext } from '../AuthContext';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const GSTPrintApprove = () => {
  const [bills, setBills] = useState([]);
  const [filters, setFilters] = useState({
    type: 'Bill',	
    year: '',
    date: '',
    dateField: 'equal',
    customerName: '',
    refNo: '',
    approvalStatus: '',
  });

  const [editingBill, setEditingBill] = useState(null);
  const [editForm, setEditForm] = useState({});
  const [customerNameOptions, setCustomerNameOptions] = useState([]);
  const [refNoOptions, setRefNoOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext);
  const username = user.username;

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const fetchBills = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const formattedFilters = { ...filters };
      if (formattedFilters.date) {
        formattedFilters.date = new Date(formattedFilters.date).toISOString().split('T')[0];
      }
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'billing/filteredBills', { params: formattedFilters });
      
      // Filter out bills that are marked as deleted
      const activeBills = response.data.filter(bill => bill.delete_status === 'No');
      setBills(activeBills);
    } catch (error) {
      setError('Error fetching bills.');
      console.error('Error fetching bills:', error);
    } finally {
      setLoading(false);
    }
  }, [filters]);
  

  useEffect(() => {
    fetchBills();
  }, [fetchBills]);

  const fetchCustomerNameOptions = useCallback(async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'billing/customerNames');
      setCustomerNameOptions(response.data);
    } catch (error) {
      console.error('Error fetching customer names:', error);
      setCustomerNameOptions([]);
    }
  }, []);

  const fetchRefNoOptions = useCallback(async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'billing/refNoOptions');
      setRefNoOptions(response.data);
    } catch (error) {
      console.error('Error fetching reference numbers:', error);
      setRefNoOptions([]);
    }
  }, []);

  useEffect(() => {
    fetchBills();
    fetchCustomerNameOptions();
    fetchRefNoOptions();
  }, [fetchBills, fetchCustomerNameOptions, fetchRefNoOptions]);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleEdit = (bill) => {
    setEditingBill(bill.bill_no);
    setEditForm(bill);
  };

  const handleEditFormChange = (e) => {
    setEditForm({ ...editForm, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      await axios.put(BASE_URL + `billing/${editingBill}`, editForm);
      fetchBills();
      setEditingBill(null);
    } catch (error) {
      console.error('Error updating bill:', error);
    }
  };

  const handleCancel = () => {
    setEditingBill(null);
  };

  const handleDelete = async (bill_no) => {
    if (window.confirm('Are you sure you want to delete this bill?')) {
      try {
        await axios.delete(BASE_URL + `billing/delete/${bill_no}`);
        fetchBills();
      } catch (error) {
        console.error('Error deleting bill:', error);
        alert(error.response.data.error);
      }
    }
  };

  const handleApproval = async (bill_no, currentStatus) => {
    const newApprovalStatus = currentStatus === 'Approved' ? 'Unapproved' : 'Approved';
    const confirmMessage = currentStatus === 'Approved' 
      ? 'Are you sure you want to unapprove this bill?' 
      : 'Are you sure you want to approve this bill?';

    if (window.confirm(confirmMessage)) {
      try {
        await axios.put(process.env.REACT_APP_BACKEND_URL + `billing/updateApprovalStatus`, {
          bill_no,
          approvalStatus: newApprovalStatus,
          approved_by: username,
        });
        fetchBills();
      } catch (error) {
        console.error('Error updating approval status:', error);
      }
    }
  };

  const handlePrint = (bill) => {
    if (!bill) {
      console.error('Bill data is missing');
      return;
    }

    const doc = new jsPDF();

    doc.text(`Bill Number: ${bill.bill_no || ''}`, 10, 10);
    doc.text(`Date: ${formatTimestamp(bill.date) || ''}`, 10, 20);
    doc.text(`Reference Number: ${bill.ref_no || ''}`, 10, 30);
    doc.text(`Customer Name: ${bill.customer_name || ''}`, 10, 40);
    doc.text(`Total Amount: ${bill.total_amount || ''}`, 10, 50);
    doc.text(`Prepared By: ${bill.prepared_by || ''}`, 10, 60);

    doc.save(`Bill_${bill.bill_no}.pdf`);
  };

  return (
    <div className="gst-management-container">
      <h1 className="gst-management-header">GST Approve and Print</h1>

      <div className="gst-filters-section">
        <input
          type="text"
          name="type"
          className="gst-filter-input"
          value={filters.type}
          readOnly
        />
        <select name="year" className="gst-filter-select" value={filters.year} onChange={handleFilterChange}>
          <option value="">Select Year</option>
          <option value="2022-2023">2022-2023</option>
          <option value="2023-2024">2023-2024</option>
          <option value="2024-2025">2024-2025</option>
        </select>

        <select name="dateField" className="gst-filter-select" value={filters.dateField} onChange={handleFilterChange}>
          <option value="equal">Equal</option>
          <option value="before">Before</option>
          <option value="after">After</option>
        </select>

        <input
          type="date"
          name="date"
          className="gst-filter-input"
          value={filters.date ? new Date(filters.date).toISOString().split('T')[0] : ''}
          onChange={handleFilterChange}
          placeholder="Date"
        />

        <select name="customerName" className="gst-filter-select" value={filters.customerName} onChange={handleFilterChange}>
          <option value="">Select Customer Name</option>
          {customerNameOptions.map((name, index) => (
            <option key={index} value={name}>{name}</option>
          ))}
        </select>

        <select name="refNo" className="gst-filter-select" value={filters.refNo} onChange={handleFilterChange}>
          <option value="">Select Ref No</option>
          {refNoOptions.map((refNo, index) => (
            <option key={index} value={refNo}>{refNo}</option>
          ))}
        </select>

        <select name="approvalStatus" className="gst-filter-select" value={filters.approvalStatus} onChange={handleFilterChange}>
          <option value="">All</option>
          <option value="Approved">Approved</option>
          <option value="Unapproved">Unapproved</option>
        </select>


        <button className="gst-filter-button" onClick={fetchBills} disabled={loading}>
          {loading ? 'Searching...' : 'Search'}
        </button>
      </div>

      {error && <div className="gst-error-message">{error}</div>}

      <table className="gst-bill-table">
        <thead className="gst-bill-table-head">
          <tr>
            <th>Bill No</th>
            <th>Date</th>
            <th>Ref No</th>
            <th>Service Type</th>
            <th>Trans Mode</th>
            <th>Customer Name</th>
            <th>Total Amount</th>
            <th>Prepared By</th>
            <th>Approved By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="gst-bill-table-body">
         {bills.map((bill) => (
           <tr key={bill.bill_no}>
             {editingBill === bill.bill_no ? (
               <>
                 <td>{bill.bill_no}</td>
                 <td>
                   <input
                     type="date"
                     name="date"
                     className="gst-edit-input"
                     value={formatTimestamp(editForm.date)}
                     onChange={handleEditFormChange}
                   />
                 </td>
                 <td>
                   <input
                     type="text"
                     name="ref_no"
                     className="gst-edit-input"
                     value={editForm.ref_no}
                     onChange={handleEditFormChange}
                   />
                 </td>
                 <td>
                   <input
                     type="text"
                     name="service_type"
                     className="gst-edit-input"
                     value={editForm.service_type}
                     onChange={handleEditFormChange}
                   />
                 </td>
                 <td>
                   <input
                     type="text"
                     name="trans_mode"
                     className="gst-edit-input"
                     value={editForm.trans_mode}
                     onChange={handleEditFormChange}
                   />
                 </td>
                 <td>
                   <input
                     type="text"
                     name="customer_name"
                     className="gst-edit-input"
                     value={editForm.customer_name}
                     onChange={handleEditFormChange}
                   />
                 </td>
                 <td>
                   <input
                     type="number"
                     name="total_amount"
                     className="gst-edit-input"
                     value={editForm.total_amount}
                     onChange={handleEditFormChange}
                   />
                 </td>
                 <td>{bill.prepared_by}</td>
                 <td>{bill.approved_by || 'N/A'}</td>
                 <td >
                   <div className="gst-actions-buttons">
                     <button className="gst-save-button" onClick={handleSave}>Save</button>
                     <button className="gst-cancel-button" onClick={handleCancel}>Cancel</button>
                   </div>
                 </td>
               </>
             ) : (
               <>
                 <td>{bill.bill_no}</td>
                 <td>{new Date(bill.date).toLocaleDateString()}</td>
                 <td>{bill.ref_no}</td>
                 <td>{bill.service_type}</td>
                 <td>{bill.trans_mode}</td>
                 <td>{bill.customer_name}</td>
                 <td>{bill.total_amount}</td>
                 <td>{bill.prepared_by}</td>
                 <td>{bill.approved_by || 'N/A'}</td>
                 <td >
                   <div className="gst-actions-buttons">
                     {bill.approval_status === 'Paid' ? (
                       <span className="gst-paid-status">Paid</span>
                     ) : (
                       <>
                         <button className="gst-edit-button" onClick={() => handleEdit(bill)} disabled={bill.approval_status === 'Approved'}>Edit</button>
                         <button className="gst-delete-button" onClick={() => handleDelete(bill.bill_no)} disabled={bill.approval_status === 'Approved'}>Delete</button>
                         <button 
                           className="gst-approve-button" 
                           onClick={() => handleApproval(bill.bill_no, bill.approval_status)}
                         >
                           {bill.approval_status === 'Approved' ? 'Unapprove' : 'Approve'}
                         </button>
                         <button className="gst-print-button" onClick={() => handlePrint(bill)}>Print</button>
                       </>
                     )}
                   </div>
                 </td>
               </>
             )} 
           </tr>  
                  ))}
                  </tbody>
                  </table>
                  </div>
  );
};

export default GSTPrintApprove;
