import React from 'react';

function Accounts() {
  return (
    <div>
      <h2>Accounts</h2>
    </div>
  );
}

export default Accounts;
