import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles/FileUpload.css';
import moment from 'moment';

const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'file-upload';

const FileUploadDetailsReport = () => {
  const [refNo, setRefNo] = useState('');
  const [refNoOptions, setRefNoOptions] = useState([]);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [filterType, setFilterType] = useState('equal');
  const [filterDate, setFilterDate] = useState('');


  useEffect(() => {
    fetchRefNoOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refNo]);

  const fetchRefNoOptions = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'import-job/ref-numbers');
      setRefNoOptions(response.data);
    } catch (err) {
      console.error('Error fetching ref numbers:', err);
      setError('Error fetching ref numbers');
    }
  };

  // const fetchFiles = async (refNo, filterType, filterDate) => {
  //   try {
  //     let url = `${BASE_URL}/report`;
  //     if (refNo) {
  //       url += `/${refNo}`;
  //     }

  //     const response = await axios.get(url, {
  //       params: { filterType, filterDate }
  //     });
  //     setFiles(response.data);
  //   } catch (err) {
  //     console.error('Error fetching files:', err);
  //     setError('Error fetching files');
  //   }
  // };




  const handleDownload = (filePath) => {
    const fileName = filePath.split('/').pop().split('\\').pop();
    window.open(`${BASE_URL}/download/${encodeURIComponent(fileName)}`, '_blank');
  };

  const handleInputChange = (e) => {
    setRefNo(e.target.value);
    // if (e.target.value) {
    //   fetchFiles(e.target.value); // Optional: Fetch files as user types
    // }
  };

  const handleFilterDateChange = (e) => {
    setFilterDate(e.target.value);
  };

  // Handler for filter type change (before, after, equal)
  const handleFilterTypeChange = (e) => {
    setFilterType(e.target.value);
  };

  const handleViewClick = async () => {
    // fetchFiles(refNo || null, filterType, filterDate);
    try {
      const base_url = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${base_url}/search?ref_no=${refNo}&d=${filterDate}`);
      console.log(response.data);
      if (response.data) {
        setFiles(response.data);
      }
    } catch (error) {
      console.log(error);
      setError(error.response?.message)
    }
  }



  return (
    <div className="file-upload-container">
      <h1 className="file-upload-title">File Upload Details Report</h1>

      <div className="file-upload-form">
        <label className="file-upload-label">Reference Number</label>
        <input
          type="text"
          className="file-upload-input"
          value={refNo}
          onChange={handleInputChange}
          list="refNoOptions"
          placeholder="Type or select reference number"
        />
        <datalist id="refNoOptions">
          {refNoOptions.map((option) => (
            <option key={option.ref_no} value={option.ref_no} />
          ))}
        </datalist>

        <label>Filter by Date</label><br /><br />
        <select
          className="file-upload-input"
          value={filterType}
          onChange={handleFilterTypeChange}
        >
          <option value="equal">Equal</option>
          <option value="before">Before</option>
          <option value="after">After</option>
        </select>
        <br />
        <input
          type="date"
          className="file-upload-input"
          value={filterDate}
          onChange={handleFilterDateChange}
        />

        <button className="incomplete-jobs-button incomplete-jobs-button-show" onClick={handleViewClick}>
          View
        </button>
      </div>


      {files.length > 0 && (
        <div className="file-upload-list">
          <h2 className="file-upload-list-title">Uploaded Files</h2>
          <table className="file-upload-table">
            <thead>
              <tr>
                <th>File Name</th>
                <th>Uploaded Time</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {files.map((file) => (
                <tr key={file.id}>
                  <td>{JSON.parse(file?.job_files).map((f, i) => (
                    <li key={i} className='list-none font-bold text-sm text-gray-500'>{f.fileName}</li>
                  ))}</td>
                  <td>{moment(file.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
                  <td>
                    <button
                      className="file-upload-download-button"
                      onClick={() => handleDownload(file.file_path)}
                    >
                      Download
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default FileUploadDetailsReport;
