import React, { useState } from 'react';
import axios from 'axios'; // Make sure you have axios installed
import './styles/DeletedInformation.css';

const DeletedInformation = () => {
  const [dateFilter, setDateFilter] = useState('equal');
  const [date, setDate] = useState('');
  const [infoType, setInfoType] = useState('');
  const [partyName, setPartyName] = useState('');
  const [ref, setRef] = useState('');
  const [year, setYear] = useState('');
  
  // State to hold the results
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(''); // Error state

  const handleSearch = async () => {
    setLoading(true); // Set loading state to true
    setError(''); // Reset error message

    try {
      // Construct the query parameters
      const params = {
        dateFilter,
        date,
        infoType,
        partyName,
        ref,
        year
      };

      // API call to fetch deleted information
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}billing/deleted/deletedInformation`, { params });
      setResults(response.data); // Update results with API response
    } catch (err) {
      setError('Error fetching data. Please try again.'); // Handle error
      console.error(err);
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  return (
    <div className="deleted-information-container">
    <h1 className="deleted-information-header">Deleted Information</h1>
    
    <div className="deleted-information-form">
      <div className="deleted-information-form-group">
        <label>Date</label>
        <select
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
          className="deleted-information-select"
        >
          <option value="before">Before</option>
          <option value="equal">Equal</option>
          <option value="after">After</option>
        </select>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="deleted-information-input"
        />
      </div>
      
      <div className="deleted-information-form-group">
        <label>Type of Information</label>
        <select
          value={infoType}
          onChange={(e) => setInfoType(e.target.value)}
          className="deleted-information-select"
        >
          <option value="">Select Type</option>
          <option value="Bill">Bill</option>
          <option value="Voucher">Voucher</option>
        </select>
      </div>
      
      <div className="deleted-information-form-group">
        <label>Party Name</label>
        <input
          type="text"
          value={partyName}
          onChange={(e) => setPartyName(e.target.value)}
          className="deleted-information-input"
        />
      </div>
      
      <div className="deleted-information-form-group">
        <label>Ref#</label>
        <input
          type="text"
          value={ref}
          onChange={(e) => setRef(e.target.value)}
          className="deleted-information-input"
        />
      </div>
      
      <div className="deleted-information-form-group">
        <label>Year</label>
        <select
          value={year}
          onChange={(e) => setYear(e.target.value)}
          className="deleted-information-select"
        >
          <option value="">Select Year</option>
          <option value="2021-2022">2021-2022</option>
          <option value="2022-2023">2022-2023</option>
          <option value="2023-2024">2023-2024</option>
          <option value="2024-2025">2024-2025</option>
        </select>
      </div>
      
      <div className="deleted-information-button-group">
        <button onClick={handleSearch} disabled={loading} className="deleted-information-button">Search</button>
      </div>
    </div>
  
    <div className="deleted-information-results">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <>
          <h2 className="deleted-information-results-header">Results</h2>
          {infoType === 'Bill' ? (
            <table className="deleted-information-table">
              <thead>
                <tr>
                  <th>Bill No</th>
                  <th>Date</th>
                  <th>Ref No</th>
                  <th>Prepared By</th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {results.map((result, index) => (
                  <tr key={index}>
                    <td>{result.bill_no}</td>
                    <td>{new Date(result.date).toLocaleDateString()}</td>
                    <td>{result.ref_no}</td>
                    <td>{result.prepared_by}</td>
                    <td>{result.total_amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : infoType === 'Voucher' ? (
            <table className="deleted-information-table">
              <thead>
                <tr>
                  <th>Voucher Number</th>
                  <th>Date</th>
                  <th>Ref No</th>
                  <th>Prepared By</th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {results.map((result, index) => (
                  <tr key={index}>
                    <td>{result.voucher_number}</td>
                    <td>{new Date(result.date).toLocaleDateString()}</td>
                    <td>{result.ref_no}</td>
                    <td>{result.prepared_by}</td>
                    <td>{result.total_amt}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>Please select a type of information to see results.</p>
          )}
        </>
      )}
    </div>
  </div>
  
  );
};

export default DeletedInformation;
