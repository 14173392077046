import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './styles/UOMMaster.css';
import { AuthContext } from '../AuthContext';

const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'uom-master';

const UOMMaster = () => {
  const [uomName, setUOMName] = useState('');
  const [uoms, setUOMs] = useState([]);
  const [inactiveUOMs, setInactiveUOMs] = useState([]);
  const [editId, setEditId] = useState(null);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [viewMode, setViewMode] = useState('active');
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteType, setDeleteType] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  const { user } = useContext(AuthContext);
  const username = user.username;

  useEffect(() => {
    fetchUOMs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);

  const fetchUOMs = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${viewMode === 'active' ? '' : '/inactive'}`);
      viewMode === 'active' ? setUOMs(response.data) : setInactiveUOMs(response.data);
    } catch (err) {
      console.error('Error fetching UOMs:', err);
      setError('Error fetching UOMs');
    }
  };

  const handleSave = async () => {
    if (!uomName) {
      setError('UOM is required');
      return;
    }

    try {
      if (editId) {
        await axios.put(`${BASE_URL}/${editId}`, { uomName, updatedBy: username });
        setEditId(null);
        setSuccessMessage('Updated successfully');
      } else {
        await axios.post(BASE_URL, { uomName, createdBy: username });
        setSuccessMessage('Saved successfully');
      }
      fetchUOMs();
      resetForm();
    } catch (err) {
      console.error('Error saving UOM:', err);
      setError('Error saving UOM');
    }
  };

  const handleEdit = (uom) => {
    setUOMName(uom.uom_name);
    setEditId(uom.id);
  };

  const handleDelete = async () => {
    const isPermanent = deleteType === 'permanent';
    try {
      await axios.delete(`${BASE_URL}/${isPermanent ? 'permanent' : 'soft'}/${deleteId}`);
      fetchUOMs();
      setShowDeleteDialog(false);
      setSuccessMessage(isPermanent ? 'Permanently deleted successfully' : 'Deleted successfully');
    } catch (err) {
      console.error(`Error ${isPermanent ? 'permanently ' : ''}deleting UOM:`, err);
      setError(`Error ${isPermanent ? 'permanently ' : ''}deleting UOM`);
    }
  };

  const handleBulkDelete = async () => {
    const isPermanent = viewMode === 'inactive';
    try {
      await Promise.all(selectedRecords.map(id => axios.delete(`${BASE_URL}/${isPermanent ? 'permanent' : 'soft'}/${id}`)));
      fetchUOMs();
      setSuccessMessage(isPermanent ? 'Permanently deleted successfully' : 'Deleted successfully');
    } catch (err) {
      console.error(`Error ${isPermanent ? 'permanently ' : ''}deleting UOMs:`, err);
      setError(`Error ${isPermanent ? 'permanently ' : ''}deleting UOMs`);
    }
  };

  const handleSearch = () => {
    fetchUOMs();
  };

  const handleReset = () => {
    setSearchTerm('');
    setFilterBy('');
    resetForm();
    fetchUOMs();
    setSuccessMessage('Reset successfully');
  };

  const resetForm = () => {
    setUOMName('');
    setEditId(null);
    setError('');
  };

  const handleCheckboxChange = (id) => {
    setSelectedRecords((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((recordId) => recordId !== id) : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    const allIds = (viewMode === 'active' ? uoms : inactiveUOMs).map(uom => uom.id);
    setSelectedRecords(selectedRecords.length === allIds.length ? [] : allIds);
  };

  const filteredUOMs = (viewMode === 'active' ? uoms : inactiveUOMs).filter((uom) => {
    if (!searchTerm) return true;
    switch (filterBy) {
      case 'uom_name':
        return uom.uom_name.includes(searchTerm);
      case 'created_by':
        return uom.created_by.includes(searchTerm);
      case 'updated_by':
        return uom.updated_by.includes(searchTerm);
      case 'month':
        return new Date(uom.created_at).getMonth() + 1 === parseInt(searchTerm);
      case 'year':
        return new Date(uom.created_at).getFullYear() === parseInt(searchTerm);
      default:
        return Object.values(uom).some(value => String(value).includes(searchTerm));
    }
  });

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
    <div className="uom-master">
    <h1 className="uom-master-title">UOM Master</h1>
    <div className="uom-master-form">
      <label className="uom-master-label">UOM</label>
      <input
        type="text"
        className="uom-master-input"
        value={uomName}
        onChange={(e) => setUOMName(e.target.value)}
      />
      <div className="uom-master-button-group">
        <button className="uom-master-button save" onClick={handleSave}>
          {editId ? 'Update' : 'Save'}
        </button>
        <button className="uom-master-button reset" onClick={handleReset}>
          Reset
        </button>
      </div>
      {error && <p className="uom-master-message error">{error}</p>}
      {successMessage && <p className="uom-master-message success">{successMessage}</p>}
    </div>
    <div className="uom-master-search-filters">
      <input
        type="text"
        className="uom-master-search-input"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <select
        className="uom-master-filter-dropdown"
        value={filterBy}
        onChange={(e) => setFilterBy(e.target.value)}
      >
        <option value="">Filter by</option>
        <option value="uom_name">UOM</option>
        <option value="created_by">Created By</option>
        <option value="updated_by">Updated By</option>
        <option value="month">Month</option>
        <option value="year">Year</option>
      </select>
      <button className="uom-master-button-search" onClick={handleSearch}>
        Search
      </button>
      <button className="uom-master-button reset" onClick={handleReset}>
        Reset
      </button>
    </div>
    <div className="uom-master-view-buttons">
      <button
        className={`uom-master-view-button ${viewMode === 'active' ? 'active' : ''}`}
        onClick={() => setViewMode('active')}
        style={{ backgroundColor: viewMode === 'active' ? 'green' : 'initial' }}
      >
        View Active
      </button>
      <button
        className={`uom-master-view-button ${viewMode === 'inactive' ? 'inactive' : ''}`}
        onClick={() => setViewMode('inactive')}
        style={{ backgroundColor: viewMode === 'inactive' ? 'red' : 'initial' }}
      >
        View Deleted
      </button>
    </div>
    {filteredUOMs.length > 0 ? (
      <>
        <table className="uom-master-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  className="uom-master-select-all-checkbox"
                  checked={selectedRecords.length === filteredUOMs.length}
                  onChange={handleSelectAll}
                />
              </th>
              <th>ID</th>
              <th>UOM</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Created By</th>
              <th>Updated By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUOMs.map((uom) => (
              <tr key={uom.id}>
                <td>
                  <input
                    type="checkbox"
                    className="uom-master-record-checkbox"
                    checked={selectedRecords.includes(uom.id)}
                    onChange={() => handleCheckboxChange(uom.id)}
                  />
                </td>
                <td>{uom.id}</td>
                <td>{uom.uom_name}</td>
                <td>{formatTimestamp(uom.created_at)}</td>
                <td>{formatTimestamp(uom.updated_at)}</td>
                <td>{uom.created_by}</td>
                <td>{uom.updated_by}</td>
                <td>
                  {viewMode === 'active' ? (
                    <>
                      <button className="uom-master-button edit" onClick={() => handleEdit(uom)}>
                        Edit
                      </button>
                      <button
                        className="uom-master-button delete"
                        onClick={() => {
                          setShowDeleteDialog(true);
                          setDeleteType('soft');
                          setDeleteId(uom.id);
                        }}
                      >
                        Delete
                      </button>
                    </>
                  ) : (
                    <button
                      className="uom-master-button delete-permanent"
                      onClick={() => {
                        setShowDeleteDialog(true);
                        setDeleteType('permanent');
                        setDeleteId(uom.id);
                      }}
                    >
                      Delete Permanently
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {selectedRecords.length > 0 && (
          <button className="uom-master-button bulk-delete" onClick={handleBulkDelete}>
            Delete Selected
          </button>
        )}
      </>
    ) : (
      <p className="uom-master-no-records-message">No UOMs found</p>
    )}
    {showDeleteDialog && (
      <div className="uom-master-dialog">
        <div className="uom-master-dialog-content">
          <p className="uom-master-dialog-text">
            Are you sure you want to {deleteType === 'permanent' ? 'permanently ' : ''}delete this UOM?
          </p>
          <button className="uom-master-button confirm" onClick={handleDelete}>
            Yes
          </button>
          <button className="uom-master-button cancel" onClick={() => setShowDeleteDialog(false)}>
            No
          </button>
        </div>
      </div>
    )}
  </div>
  );
};

export default UOMMaster;
