import React, { useContext } from 'react';
import './styles/Header.css';
import logo from '../assets/logo.png';
import { AuthContext } from '../AuthContext';

const Header = () => {
  const { user } = useContext(AuthContext);
  const username = user ? user.username : '';
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Jetway Group" />
        <div>JETWAY GROUP</div>
      </div>
      <div className="welcome">
         <div>Welcome {username}</div>
         <div>Year:{new Date().getFullYear()}</div>
      </div>

    </header>
  );
}

export default Header;
