// Register.js
import React, { useState } from 'react';
//import './styles/Register.css';

function Register({ onRegister }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
    try {
      const response = await fetch('/api/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      if (response.ok) {
        onRegister(username);
      } else {
        setMessage(data.message);
      }
    } catch (err) {
      setMessage('Error registering');
    }
  };

  return (
    <div className="register-page">
      <header className="register-header">
        <div className="header-logo">
          <img src="logo.png" alt="Jetway Group" className="logo" />
          <div className="header-text">JETWAY GROUP</div>
        </div>
      </header>
      <main className="register-main">
        <form onSubmit={handleSubmit} className="register-form">
          <h2>Register</h2>
          <div className="register-form-group">
            <label>Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="register-form-group">
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="register-form-group">
            <label>Confirm Password:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="register-button">Register</button>
          {message && <p className="error-message">{message}</p>}
        </form>
      </main>
    </div>
  );
}

export default Register;
