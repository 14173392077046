import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './styles/InvoiceHead.css';
import { AuthContext } from '../AuthContext';

const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'invoice-head';

const InvoiceHead = () => {
  const [invoiceHeadName, setInvoiceHeadName] = useState('');
  const [invoiceHeads, setInvoiceHeads] = useState([]);
  const [inactiveInvoiceHeads, setInactiveInvoiceHeads] = useState([]);
  const [editId, setEditId] = useState(null);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [viewMode, setViewMode] = useState('active');
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteType, setDeleteType] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  const { user } = useContext(AuthContext);
  const username = user.username;

  useEffect(() => {
    fetchInvoiceHeads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);

  const fetchInvoiceHeads = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${viewMode === 'active' ? '' : '/inactive'}`);
      viewMode === 'active' ? setInvoiceHeads(response.data) : setInactiveInvoiceHeads(response.data);
    } catch (err) {
      console.error('Error fetching invoice heads:', err);
      setError('Error fetching invoice heads');
    }
  };

  const handleSave = async () => {
    if (!invoiceHeadName) {
      setError('Invoice Head Name is required');
      return;
    }

    try {
      if (editId) {
        await axios.put(`${BASE_URL}/${editId}`, { invoiceHeadName, updatedBy: username });
        setEditId(null);
        setSuccessMessage('Updated successfully');
      } else {
        await axios.post(BASE_URL, { invoiceHeadName, createdBy: username });
        setSuccessMessage('Saved successfully');
      }
      fetchInvoiceHeads();
      resetForm();
    } catch (err) {
      console.error('Error saving invoice head:', err);
      setError('Error saving invoice head');
    }
  };

  const handleEdit = (invoiceHead) => {
    setInvoiceHeadName(invoiceHead.invoice_head_name);
    setEditId(invoiceHead.id);
  };

  const handleDelete = async () => {
    const isPermanent = deleteType === 'permanent';
    try {
      await axios.delete(`${BASE_URL}/${isPermanent ? 'permanent' : 'soft'}/${deleteId}`);
      fetchInvoiceHeads();
      setShowDeleteDialog(false);
      setSuccessMessage(isPermanent ? 'Permanently deleted successfully' : 'Deleted successfully');
    } catch (err) {
      console.error(`Error ${isPermanent ? 'permanently ' : ''}deleting invoice head:`, err);
      setError(`Error ${isPermanent ? 'permanently ' : ''}deleting invoice head`);
    }
  };

  const handleBulkDelete = async () => {
    const isPermanent = viewMode === 'inactive';
    try {
      await Promise.all(selectedRecords.map(id => axios.delete(`${BASE_URL}/${isPermanent ? 'permanent' : 'soft'}/${id}`)));
      fetchInvoiceHeads();
      setSuccessMessage(isPermanent ? 'Permanently deleted successfully' : 'Deleted successfully');
    } catch (err) {
      console.error(`Error ${isPermanent ? 'permanently ' : ''}deleting invoice heads:`, err);
      setError(`Error ${isPermanent ? 'permanently ' : ''}deleting invoice heads`);
    }
  };

  const handleSearch = () => {
    fetchInvoiceHeads();
  };

  const handleReset = () => {
    setSearchTerm('');
    setFilterBy('');
    resetForm();
    fetchInvoiceHeads();
    setSuccessMessage('Reset successfully');
  };

  const resetForm = () => {
    setInvoiceHeadName('');
    setEditId(null);
    setError('');
  };

  const handleCheckboxChange = (id) => {
    setSelectedRecords((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((recordId) => recordId !== id) : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    const allIds = (viewMode === 'active' ? invoiceHeads : inactiveInvoiceHeads).map(invoiceHead => invoiceHead.id);
    setSelectedRecords(selectedRecords.length === allIds.length ? [] : allIds);
  };

  const filteredInvoiceHeads = (viewMode === 'active' ? invoiceHeads : inactiveInvoiceHeads).filter((invoiceHead) => {
    if (!searchTerm) return true;
    switch (filterBy) {
      case 'invoice_head_name':
        return invoiceHead.invoice_head_name.includes(searchTerm);
      case 'created_by':
        return invoiceHead.created_by.includes(searchTerm);
      case 'updated_by':
        return invoiceHead.updated_by.includes(searchTerm);
      case 'month':
        return new Date(invoiceHead.created_at).getMonth() + 1 === parseInt(searchTerm);
      case 'year':
        return new Date(invoiceHead.created_at).getFullYear() === parseInt(searchTerm);
      default:
        return Object.values(invoiceHead).some(value => String(value).includes(searchTerm));
    }
  });

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
<div className="invoice-head">
  <h1 className="invoice-head-title">Invoice Head</h1>
  <div className="invoice-head-form">
    <label className="invoice-head-label">Invoice Head Name</label>
    <input
      type="text"
      className="invoice-head-input"
      value={invoiceHeadName}
      onChange={(e) => setInvoiceHeadName(e.target.value)}
    />
    <div className="invoice-head-button-group">
      <button className="invoice-head-button save" onClick={handleSave}>
        {editId ? 'Update' : 'Save'}
      </button>
      <button className="invoice-head-button reset" onClick={handleReset}>
        Reset
      </button>
    </div>
    {error && <p className="invoice-head-message error">{error}</p>}
    {successMessage && <p className="invoice-head-message success">{successMessage}</p>}
  </div>
  <div className="invoice-head-search-filters">
    <input
      type="text"
      className="invoice-head-search-input"
      placeholder="Search"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
    <select
      className="invoice-head-filter-dropdown"
      value={filterBy}
      onChange={(e) => setFilterBy(e.target.value)}
    >
      <option value="">Filter by</option>
      <option value="invoice_head_name">Invoice Head Name</option>
      <option value="created_by">Created By</option>
      <option value="updated_by">Updated By</option>
      <option value="month">Month</option>
      <option value="year">Year</option>
    </select>
    <button className="invoice-head-button-search" onClick={handleSearch}>
      Search
    </button>
    <button className="invoice-head-button reset" onClick={handleReset}>
      Reset
    </button>
  </div>
  <div className="invoice-head-view-buttons">
    <button
      className={`invoice-head-view-button ${viewMode === 'active' ? 'active' : ''}`}
      onClick={() => setViewMode('active')}
      style={{ backgroundColor: viewMode === 'active' ? 'green' : 'initial' }}
    >
      View Active
    </button>
    <button
      className={`invoice-head-view-button ${viewMode === 'inactive' ? 'inactive' : ''}`}
      onClick={() => setViewMode('inactive')}
      style={{ backgroundColor: viewMode === 'inactive' ? 'red' : 'initial' }}
    >
      View Deleted
    </button>
  </div>
  {filteredInvoiceHeads.length > 0 ? (
    <>
      <table className="invoice-head-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                className="invoice-head-select-all-checkbox"
                checked={selectedRecords.length === filteredInvoiceHeads.length}
                onChange={handleSelectAll}
              />
            </th>
            <th>ID</th>
            <th>Invoice Head Name</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Created By</th>
            <th>Updated By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredInvoiceHeads.map((invoiceHead) => (
            <tr key={invoiceHead.id}>
              <td>
                <input
                  type="checkbox"
                  className="invoice-head-record-checkbox"
                  checked={selectedRecords.includes(invoiceHead.id)}
                  onChange={() => handleCheckboxChange(invoiceHead.id)}
                />
              </td>
              <td>{invoiceHead.id}</td>
              <td>{invoiceHead.invoice_head_name}</td>
              <td>{formatTimestamp(invoiceHead.created_at)}</td>
              <td>{formatTimestamp(invoiceHead.updated_at)}</td>
              <td>{invoiceHead.created_by}</td>
              <td>{invoiceHead.updated_by}</td>
              <td>
                {viewMode === 'active' ? (
                  <>
                    <button className="invoice-head-button edit" onClick={() => handleEdit(invoiceHead)}>
                      Edit
                    </button>
                    <button
                      className="invoice-head-button delete"
                      onClick={() => {
                        setShowDeleteDialog(true);
                        setDeleteType('soft');
                        setDeleteId(invoiceHead.id);
                      }}
                    >
                      Delete
                    </button>
                  </>
                ) : (
                  <button
                    className="invoice-head-button delete-permanent"
                    onClick={() => {
                      setShowDeleteDialog(true);
                      setDeleteType('permanent');
                      setDeleteId(invoiceHead.id);
                    }}
                  >
                    Permanent Delete
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedRecords.length > 0 && (
        <button className="invoice-head-button bulk-delete" onClick={handleBulkDelete}>
          Delete Selected
        </button>
      )}
    </>
  ) : (
    <p className="invoice-head-no-records-message">No invoice heads found</p>
  )}

  {showDeleteDialog && (
    <div className="invoice-head-dialog">
      <div className="invoice-head-dialog-content">
        <p className="invoice-head-dialog-text">
          Are you sure you want to {deleteType === 'permanent' ? 'permanently ' : ''}delete this invoice head?
        </p>
        <div className="invoice-head-dialog-buttons">
          <button className="invoice-head-button confirm" onClick={handleDelete}>
            Yes
          </button>
          <button className="invoice-head-button cancel" onClick={() => setShowDeleteDialog(false)}>
            No
          </button>
        </div>
      </div>
    </div>
  )}
</div>

  );
};

export default InvoiceHead;
