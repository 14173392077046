import React, { useState, useEffect, useCallback } from 'react';
import './styles/VoucherPayment.css';
import axios from 'axios';

const VoucherPayment = () => {
  const [filters, setFilters] = useState({
    paymentDateOption: 'equal',
    paymentDate: '',
    paid_date: '',
    party: '',
    refNo: '',
    totalAmount: 0,
    year: '2023-2024',
    category: '',
    voucherStatus: '',
  });
  const [date, setDate] = useState('');
  const [vouchers, setVouchers] = useState([]);
  const [selectedVouchers, setSelectedVouchers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [partySuggestions, setPartySuggestions] = useState([]);
  const [refNoSuggestions, setRefNoSuggestions] = useState([]);
  const [categorySuggestions, setCategorySuggestions] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const fetchPartySuggestions = async (query) => {
    if (!query) return; // Prevent empty term requests
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}suggestions`, {
        params: { type: 'party', term: query },
      });
      setPartySuggestions(response.data);
    } catch (error) {
      console.error('Error fetching party suggestions:', error);
    }
  };

  const fetchRefNoSuggestions = async (query) => {
    if (!query) return; // Prevent empty term requests
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}suggestions`, {
        params: { type: 'refNo', term: query },
      });
      setRefNoSuggestions(response.data);
    } catch (error) {
      console.error('Error fetching ref no suggestions:', error);
    }
  };

  const fetchCategorySuggestions = async (query) => {
    if (!query) return; // Prevent empty term requests
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}suggestions`, {
        params: { type: 'category', term: query },
      });
      setCategorySuggestions(response.data);
    } catch (error) {
      console.error('Error fetching category suggestions:', error);
    }
  };

  const handlePartyInputChange = (e) => {
    setFilters({ ...filters, party: e.target.value });
    fetchPartySuggestions(e.target.value);
  };

  const handleRefNoInputChange = (e) => {
    setFilters({ ...filters, refNo: e.target.value });
    fetchRefNoSuggestions(e.target.value);
  };

  const handleCategoryInputChange = (e) => {
    setFilters({ ...filters, category: e.target.value });
    fetchCategorySuggestions(e.target.value);
  };

  // Fetch vouchers based on filters
  const fetchVouchers = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const params = {
        ...filters,
        ...(filters.paymentDate && {
          paymentDate: filters.paymentDate,
          paymentDateOption: filters.paymentDateOption,
        }),
      };
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}voucher-payment`, { params });
      const activeVouchers = response.data.filter(voucher => voucher.delete_status === 'No');
      setVouchers(activeVouchers);
    } catch (error) {
      setError('Error fetching vouchers.');
      console.error('Error fetching vouchers:', error);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchVouchers();
  };

  useEffect(() => {
    const total = vouchers.reduce((sum, voucher) => sum + parseFloat(voucher.total_amt), 0).toFixed(2);
    setFilters((prevFilters) => ({
      ...prevFilters,
      totalAmount: total,
    }));
  }, [vouchers]);

  const calculateAmounts = () => {
    const unpaidVouchers = selectedVouchers.filter(voucher => voucher.approval_status !== 'Paid');
  
    const amountToBePaid = unpaidVouchers.reduce((sum, voucher) => sum + parseFloat(voucher.total_amt), 0).toFixed(2);
    const balance = (filters.totalAmount - amountToBePaid).toFixed(2);
  
    return {
      amountToBePaid,
      balance,
    };
  };

  const handleCheckboxChange = (e, voucher) => {
    setShowPopup(true);
    if (e.target.checked) {
      setSelectedVouchers((prevSelected) => [...prevSelected, voucher]);
    } else {
      setSelectedVouchers((prevSelected) =>
        prevSelected.filter((item) => item.id !== voucher.id)
      );
    }
  };

  const handleSelectAllChange = (e) => {
    setShowPopup(true);
    if (e.target.checked) {
      const nonPaidVouchers = vouchers.filter((voucher) => voucher.approval_status !== 'Paid');
      setSelectedVouchers(nonPaidVouchers);
    } else {
      setSelectedVouchers([]);
    }
  };

  const handlePayment = async () => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}voucher-entry/update`, {
        voucherIds: selectedVouchers.map((v) => v.id),
        paymentDate: new Date().toISOString().split('T')[0],
      });
      console.log(response.data);
      fetchVouchers();
      setSelectedVouchers([]);
      alert('Paid successfully');
    } catch (error) {
      console.error('Error updating payment status:', error);
    }
    setShowPopup(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Function to handle filter changes
  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  // Calculate amounts to be paid and balance
  const { amountToBePaid, balance } = calculateAmounts();

  return (
    <div className="voucher-payment-container">
      <form className="voucher-payment-form" onSubmit={handleSubmit}>
        <h2>Voucher Payment</h2><br />
        <div className="form-group">
          <label>Date Option:</label>
          <select name="paymentDateOption" className="voucher-payment-select" value={filters.paymentDateOption} onChange={handleFilterChange}>
            <option value="equal">Equal</option>
            <option value="before">Before</option>
            <option value="after">After</option>
          </select>
        </div>
        <div className="form-group">
          <label>Date:</label>
          <input type="date" name="paymentDate" className="voucher-payment-input" value={filters.paymentDate} onChange={handleFilterChange} />
        </div>
        <div className="form-group">
          <label>Paid Date:</label>
          <input type="date" name="paid_date" className="voucher-payment-input" value={filters.paid_date} onChange={handleFilterChange} />
        </div>
        <div className="form-group">
          <label>Party:</label>
          <input
            type="text"
            name="party"
            className="voucher-payment-input"
            value={filters.party}
            onChange={handlePartyInputChange}
            list="partySuggestions"
          />
          <datalist id="partySuggestions">
            {partySuggestions.map((suggestion, index) => (
              <option key={index} value={suggestion.party} />
            ))}
          </datalist>
        </div>
        <div className="form-group">
          <label>Ref No:</label>
          <input
            type="text"
            name="refNo"
            className="voucher-payment-input"
            value={filters.refNo}
            onChange={handleRefNoInputChange}
            list="refNoSuggestions"
          />
          <datalist id="refNoSuggestions">
            {refNoSuggestions.map((suggestion, index) => (
              <option key={index} value={suggestion.ref_no} />
            ))}
          </datalist>
        </div>
        <div className="form-group">
          <label>Total Amount:</label>
          <input type="number" name="totalAmount" className="voucher-payment-input" value={filters.totalAmount} readOnly />
        </div>
        <div className="form-group">
          <label>Year:</label>
          <select name="year" className="voucher-payment-select" value={filters.year} onChange={handleFilterChange}>
            <option value="2023-2024">2023-2024</option>
            <option value="2024-2025">2024-2025</option>
          </select>
        </div>
        <div className="form-group">
          <label>Category:</label>
          <input
            type="text"
            name="category"
            className="voucher-payment-input"
            value={filters.category}
            onChange={handleCategoryInputChange}
            list="categorySuggestions"
          />
          <datalist id="categorySuggestions">
            {categorySuggestions.map((suggestion, index) => (
              <option key={index} value={suggestion.expense_type} />
            ))}
          </datalist>
        </div>
        <div className="form-group">
          <label>Voucher Status:</label>
          <input
            type="text"
            name="voucherStatus"
            className="voucher-payment-input"
            value={filters.voucherStatus}
            onChange={handleFilterChange}
            list="voucherStatusSuggestions"
          />
          <datalist id="voucherStatusSuggestions">
            <option value="Approved" />
            <option value="Unapproved" />
            <option value="Paid" />
          </datalist>
        </div>
        <button type="submit" className="voucher-payment-btn-submit">Show</button>
      </form>

      {loading ? (
        <p className="voucher-payment-loading">Loading...</p>
      ) : error ? (
        <p className="voucher-payment-error-message">{error}</p>
      ) : (
        <table className="voucher-payment-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAllChange}
                  checked={
                    vouchers.length > 0 && selectedVouchers.length === vouchers.filter(voucher => voucher.approval_status !== 'Paid').length
                  }
                />
              </th>
              <th>Voucher No.</th>
              <th>Voucher Date</th>
              <th>Ref No</th>
              <th>Party</th>
              <th>Mode</th>
              <th>Bill Type</th>
              <th>Total Amount</th>
              <th>Prepared By</th>
              <th>Mode of Payment</th>
              <th>Approval Status</th>
              <th>Approved By</th>
              <th>Paid Date</th>
            </tr>
          </thead>
          <tbody>
            {vouchers.map((voucher) => (
              <tr key={voucher.id}>
                <td>
                  {voucher.approval_status !== 'Paid' && (
                    <input
                      type="checkbox"
                      checked={selectedVouchers.some((item) => item.id === voucher.id)}
                      onChange={(e) => handleCheckboxChange(e, voucher)}
                    />
                  )}
                </td>
                <td>{voucher.voucher_number}</td>
                <td>{new Date(voucher.date).toLocaleDateString()}</td>
                <td>{voucher.ref_no}</td>
                <td>{`${voucher.party} ${voucher.voucher_head}`}</td>
                <td>{voucher.mode}</td>
                <td>{`${voucher.service_type} - ${voucher.mode} - ${voucher.expense_type}`}</td>
                <td>{voucher.total_amt}</td>
                <td>{voucher.prepared_by}</td>
                <td>{voucher.payment_mode}</td>
                <td>{voucher.approval_status}</td>
                <td>{voucher.approved_by}</td>
                <td>{new Date(voucher.paid_date).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {selectedVouchers.length > 0 && showPopup && (
        <div className="voucher-payment-summary">
          <p>Total Amount to be Paid: ₹{amountToBePaid}</p>
          <p>Total Amount: ₹{filters.totalAmount}</p>
          <p>Balance: ₹{balance}</p>
          <button onClick={handlePayment} className="main-bill-popup-button main-bill-popup-save-button">Pay Selected</button>
          <button type="button" onClick={handleClosePopup} className="main-bill-popup-button main-bill-popup-cancel-button">Cancel</button>
        </div>
      )}
    </div>
  );
};

export default VoucherPayment;
