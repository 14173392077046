import React from 'react';

function JobDetails() {
  return (
    <div>
      <h2>Job Details</h2>
    </div>
  );
}

export default JobDetails;
