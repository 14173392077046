import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles/UnbilledInformation.css'; // Assuming you have CSS for styling

const UnbilledInformation = () => {
  const [dateFilter, setDateFilter] = useState('equal');
  const [date, setDate] = useState('');
  const [year, setYear] = useState('');
  const [importerName, setImporterName] = useState('');
  const [refNo, setRefNo] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSearch = async () => {
    setLoading(true);
    setError('');

    try {
      const params = { dateFilter, date, year, importerName, refNo };
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}billing/unbilled/unbilledInformation`, { params });	
      console.log(response.data);
      setResults(response.data);
    } catch (err) {
      setError('Error fetching data. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Automatically set the year based on the selected date
  useEffect(() => {
    if (date) {
      const selectedYear = new Date(date).getFullYear();
      setYear(`${selectedYear}-${selectedYear + 1}`);
    }
  }, [date]);

  return (
<div className="unbilled-information-container">
  <h1 className="unbilled-information-header">Unbilled Information</h1>
  
  <div className="unbilled-information-form">
    <div className="unbilled-information-form-group">
      <label>Date</label>
      <select
        value={dateFilter}
        onChange={(e) => setDateFilter(e.target.value)}
        className="unbilled-information-select"
      >
        <option value="before">Before</option>
        <option value="equal">Equal</option>
        <option value="after">After</option>
      </select>
      <input
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        className="unbilled-information-input"
      />
    </div>
    
    <div className="unbilled-information-form-group">
      <label>Year</label>
      <input
        type="text"
        value={year}
        readOnly
        className="unbilled-information-input"
      />
    </div>
    
    <div className="unbilled-information-form-group">
      <label>Importer Name</label>
      <input
        type="text"
        value={importerName}
        onChange={(e) => setImporterName(e.target.value)}
        className="unbilled-information-input"
      />
    </div>
    
    <div className="unbilled-information-form-group">
      <label>Ref#</label>
      <input
        type="text"
        value={refNo}
        onChange={(e) => setRefNo(e.target.value)}
        className="unbilled-information-input"
      />
    </div>
    
    <div className="unbilled-information-button-group">
      <button onClick={handleSearch} disabled={loading} className="unbilled-information-button">Search</button>
    </div>
  </div>

  <div className="unbilled-information-results">
    {loading ? (
      <p>Loading...</p>
    ) : error ? (
      <p className="error">{error}</p>
    ) : (
      <table className="unbilled-information-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Ref No</th>
            <th>Importer Name</th>
            <th>Consignor Name</th>
            <th>Cleared On</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result, index) => (
            <tr key={index}>
              <td>{new Date(result.date).toLocaleDateString()}</td>
              <td>{result.ref_no}</td>
              <td>{result.importer_name}</td>
              <td>{result.consignor_name}</td>
              <td>{result.cleared_on ? result.cleared_on : 'Not Cleared'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
</div>

  );
};

export default UnbilledInformation;
