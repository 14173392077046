import axios from 'axios';
import React, { useEffect, useState } from 'react'

const useFetch = () => {
    const [currencies, setCurencies] = useState([]);
    const [units, setUnits] = useState([]);
    const [errors, setErrors] = useState(null);

    const base_url = process.env.REACT_APP_BACKEND_URL;

    const listCurrencies = async () => {
        try {
            const results = await axios.get(`${base_url}/currencies`);
            setCurencies(results.data.data);
        } catch (error) {
            if (error.response) {
                setErrors(error.response.data.message)
            }
        }
    }
    const listUnits = async () => {
        try {
            const results = await axios.get(`${base_url}/units`);
            console.log(results.data.data);
            setUnits(results.data.data);
        } catch (error) {
            if (error.response) {
                setErrors(error.response.data.message)
            }
        }
    }
    useEffect(() => {
        listCurrencies()
        listUnits()
    }, [])
    return {
        currencies,
        units,
        errors
    }
}

export default useFetch