// Login.js
import React, { useState, useContext } from 'react';
import './styles/Login.css';
import jetway from '../assets/jetwaygroups.jpeg';
import logo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const { login } = useContext(AuthContext);
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    console.log("Please respond", process.env.REACT_APP_BACKEND_URL)
    e.preventDefault();
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + 'login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      console.log(res)

      if (res.ok) {
        const data = await res.json();
        console.log(data)
        login(data.token, data.user);

        navigate('/dashboard');
      } else {
        setMessage('Login failed. Check your credentials.');
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-image">
          <img src={jetway} alt="Jetway Group" />
        </div>
        <div className="login-form-container">
          <header className="login-header">
            <div className="header-logo">
              <img src={logo} alt="Jetway Group" className="logo" />
              <div className="header-text">JETWAY GROUP</div>
            </div>
          </header>
          <form className="login-form" onSubmit={handleSubmit}>
            <h2>Login</h2>
            {message && <p className="error-message">{message}</p>}
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="submit">Login</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
