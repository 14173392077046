import React, { useState } from 'react';
import './styles/ExportJobRegistration.css';

const ExportJobRegistration = () => {
    const [exporterName, setExporterName] = useState('');
    const [transMode, setTransMode] = useState('Sea');
    const [refNo, setRefNo] = useState('');
    const [idCounter, setIdCounter] = useState(1);
    const [country, setCountry] = useState('');
    const [port, setPort] = useState('');
    const [motherVesselName, setMotherVesselName] = useState('');
    const [motherVesselVNo, setMotherVesselVNo] = useState('');
    const [motherVesselETA, setMotherVesselETA] = useState('');
    const [remainder, setRemainder] = useState(false);
    const [feederVesselName, setFeederVesselName] = useState('');
    const [feederVesselVNo, setFeederVesselVNo] = useState('');
    const [feederVesselETA, setFeederVesselETA] = useState('');
    const [forwarder, setForwarder] = useState('');
    const [shippingLine, setShippingLine] = useState('');
    const [rotationNo, setRotationNo] = useState('');
    const [lineDate, setLineDate] = useState('');
    const [lineNo, setLineNo] = useState('');
    const [MBLNo, setMBLNo] = useState('');
    const [MBLDate, setMBLDate] = useState('');
    const [HBLNo, setHBLNo] = useState('');
    const [HBLDate, setHBLDate] = useState('');
    const [CBM, setCBM] = useState('');
    const [CFS, setCFS] = useState('');
    const [netWT, setNetWT] = useState('');
    const [grossWT, setGrossWT] = useState('');
    const [noOfPkgs, setNoOfPkgs] = useState('');
    const [containerNo, setContainerNo] = useState('');
    const [STC, setSTC] = useState('');
    const [STC2, setSTC2] = useState('');
    const [size20, setSize20] = useState('');
    const [size40, setSize40] = useState('');
    const [totalContainer, setTotalContainer] = useState('');
    const [BENumber, setBENumber] = useState('');
    const [BEDate, setBEDate] = useState('');
    const [BEValue, setBEValue] = useState('');
    const [dutyAmount, setDutyAmount] = useState('');
    const [TR6ChallanNo, setTR6ChallanNo] = useState('');
    const [TR6ChallanDate, setTR6ChallanDate] = useState('');
    const [clearedOn, setClearedOn] = useState('');
    const [transportDetails, setTransportDetails] = useState('');
    const [incidentalExpenses, setIncidentalExpenses] = useState('');
    const [OTDetails, setOTDetails] = useState(false);
    const [charges, setCharges] = useState('');
    const [haltingDays, setHaltingDays] = useState('');
    const [freightDetails, setFreightDetails] = useState('');
    const [remarks, setRemarks] = useState('');

    const handleGenerateRefNo = () => {
        const year = new Date().getFullYear().toString().slice(-2);
        const transModeLetter = transMode[0].toUpperCase();
        const exporterLetter = exporterName[0].toUpperCase();
        const idString = idCounter.toString().padStart(6, '0');
        const generatedRefNo = `${year}${transModeLetter}${exporterLetter}${idString}`;
        setRefNo(generatedRefNo);
        setIdCounter(idCounter + 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Implement submit logic here
        console.log('Form Submitted');
    };

    const handleDelete = () => {
        // Implement delete logic here
        console.log('Record Deleted');
    };

    return (
        <div className="export-job-registration">
            <form onSubmit={handleSubmit}>
                {/* First Row */}
                <div className="row">
                    <label>Date: <input type="date" required /></label>
                    <label>Service Type: <input type="text" value="Export" disabled /></label>
                    <label>Export No.: <input type="text" /></label>
                    <label>Job/ID No.: <input type="text" /></label>
                    <label>Date: <input type="date" required /></label>
                </div>

                {/* Second Row */}
                <div className="row">
                    <label>Trans Mode: 
                        <select value={transMode} onChange={(e) => setTransMode(e.target.value)}>
                            <option value="Sea">Sea</option>
                            <option value="Air">Air</option>
                        </select>
                    </label>
                    <label>Branch: 
                        <select>
                            <option value="Chennai">Chennai</option>
                            <option value="Bangalore">Bangalore</option>
                        </select>
                    </label>
                    <label>S/B Type: 
                        <select>
                            <option value="SEZ">SEZ</option>
                            <option value="HOME">HOME</option>
                        </select>
                    </label>
                </div>

                {/* Third Row */}
                <div className="row">
                    <label>Exporter Name: <input type="text" value={exporterName} onChange={(e) => setExporterName(e.target.value)} /></label>
                    <label>Consignee Name: <input type="text" /></label>
                    <label>Ref No.: <input type="text" value={refNo} readOnly /></label>
                    <button type="button" onClick={handleGenerateRefNo}>Fetch Ref No.</button>
                </div>

                {/* Shipping Details Title */}
                <div className="row-title">Shipping Details</div>

                {/* Fourth Row */}
                <div className="row">
                    <label>Country of Shipment: 
                        <select value={country} onChange={(e) => setCountry(e.target.value)}>
                            <option value="India">India</option>
                            <option value="USA">USA</option>
                            <option value="Germany">Germany</option>
                        </select>
                    </label>
                    <label>Port of Shipment: 
                        <select value={port} onChange={(e) => setPort(e.target.value)}>
                            {country === 'India' && (
                                <>
                                    <option value="Chennai">Chennai</option>
                                    <option value="Mumbai">Mumbai</option>
                                </>
                            )}
                            {country === 'USA' && (
                                <>
                                    <option value="New York">New York</option>
                                    <option value="Los Angeles">Los Angeles</option>
                                </>
                            )}
                            {country === 'Germany' && (
                                <>
                                    <option value="Hamburg">Hamburg</option>
                                    <option value="Bremen">Bremen</option>
                                </>
                            )}
                        </select>
                    </label>
                </div>

                {/* Fifth Row */}
                <div className="row">
                    <label>Mother Vessel Name: <input type="text" value={motherVesselName} onChange={(e) => setMotherVesselName(e.target.value)} /></label>
                    <label>V No.: <input type="text" value={motherVesselVNo} onChange={(e) => setMotherVesselVNo(e.target.value)} /></label>
                    <label>ETA: <input type="date" value={motherVesselETA} onChange={(e) => setMotherVesselETA(e.target.value)} /></label>
                    <label>Remainder: <input type="checkbox" checked={remainder} onChange={(e) => setRemainder(e.target.checked)} /></label>
                </div>

                {/* Sixth Row */}
                <div className="row">
                    <label>Feeder Vessel Name: <input type="text" value={feederVesselName} onChange={(e) => setFeederVesselName(e.target.value)} /></label>
                    <label>V No.: <input type="text" value={feederVesselVNo} onChange={(e) => setFeederVesselVNo(e.target.value)} /></label>
                    <label>ETA: <input type="date" value={feederVesselETA} onChange={(e) => setFeederVesselETA(e.target.value)} /></label>
                    <label>Forwarder: <input type="text" value={forwarder} onChange={(e) => setForwarder(e.target.value)} /></label>
                </div>

                {/* Seventh Row */}
                <div className="row">
                    <label>Shipping Line: <input type="text" value={shippingLine} onChange={(e) => setShippingLine(e.target.value)} /></label>
                    <label>Rotation #: <input type="text" value={rotationNo} onChange={(e) => setRotationNo(e.target.value)} /></label>
                    <label>Date: <input type="date" value={lineDate} onChange={(e) => setLineDate(e.target.value)} /></label>
                    <label>Line No.: <input type="text" value={lineNo} onChange={(e) => setLineNo(e.target.value)} /></label>
                </div>

                {/* Eighth Row */}
                <div className="row">
                    <label>MBL No.: <input type="text" value={MBLNo} onChange={(e) => setMBLNo(e.target.value)} /></label>
                    <label>Date: <input type="date" value={MBLDate} onChange={(e) => setMBLDate(e.target.value)} /></label>
                    <label>HBL No.: <input type="text" value={HBLNo} onChange={(e) => setHBLNo(e.target.value)} /></label>
                    <label>Date: <input type="date" value={HBLDate} onChange={(e) => setHBLDate(e.target.value)} /></label>
                </div>

                {/* Ninth Row */}
                <div className="row">
                    <label>CBM: <input type="text" value={CBM} onChange={(e) => setCBM(e.target.value)} /></label>
                    <label>CFS: <input type="text" value={CFS} onChange={(e) => setCFS(e.target.value)} /></label>
                </div>

                {/* Tenth Row */}
                <div className="row">
                    <label>Net WT: <input type="text" value={netWT} onChange={(e) => setNetWT(e.target.value)} />
                        <select>
                            <option value="kgs">kgs</option>
                            <option value="tons">tons</option>
                            <option value="ltrs">ltrs</option>
                        </select>
                    </label>
                    <label>Gross WT: <input type="text" value={grossWT} onChange={(e) => setGrossWT(e.target.value)} />
                        <select>
                            <option value="kgs">kgs</option>
                            <option value="tons">tons</option>
                            <option value="ltrs">ltrs</option>
                        </select>
                    </label>
                    <label>No. of Pkgs: <input type="text" value={noOfPkgs} onChange={(e) => setNoOfPkgs(e.target.value)} />
                        <select>
                            <option value="kgs">kgs</option>
                            <option value="tons">tons</option>
                            <option value="ltrs">ltrs</option>
                        </select>
                    </label>
                    <label>Container No.: <input type="text" value={containerNo} onChange={(e) => setContainerNo(e.target.value)} /></label>
                </div>

                {/* Eleventh Row */}
                <div className="row">
                    <label>STC: <input type="text" value={STC} onChange={(e) => setSTC(e.target.value)} />
                        <select>
                            <option value="kgs">kgs</option>
                            <option value="tons">tons</option>
                            <option value="ltrs">ltrs</option>
                        </select>
                    </label>
                    <label>STC 2: <input type="text" value={STC2} onChange={(e) => setSTC2(e.target.value)} />
                        <select>
                            <option value="kgs">kgs</option>
                            <option value="tons">tons</option>
                            <option value="ltrs">ltrs</option>
                        </select>
                    </label>
                    <label>Size 20: <input type="text" value={size20} onChange={(e) => setSize20(e.target.value)} /></label>
                    <label>Size 40: <input type="text" value={size40} onChange={(e) => setSize40(e.target.value)} /></label>
                    <label>Total Container: <input type="text" value={totalContainer} onChange={(e) => setTotalContainer(e.target.value)} /></label>
                </div>

                {/* Invoice Details Title */}
                <div className="row-title">Invoice Details</div>

                {/* Document Details Title */}
                <div className="row-title">Document Details</div>

                {/* Twelfth Row */}
                <div className="row">
                    <label>B.E. Number: <input type="text" value={BENumber} onChange={(e) => setBENumber(e.target.value)} /></label>
                    <label>Date: <input type="date" value={BEDate} onChange={(e) => setBEDate(e.target.value)} /></label>
                    <label>Value: <input type="text" value={BEValue} onChange={(e) => setBEValue(e.target.value)} /></label>
                </div>

                {/* Thirteenth Row */}
                <div className="row">
                    <label>Duty Amount: <input type="text" value={dutyAmount} onChange={(e) => setDutyAmount(e.target.value)} /></label>
                    <label>TR6 Challan No.: <input type="text" value={TR6ChallanNo} onChange={(e) => setTR6ChallanNo(e.target.value)} /></label>
                    <label>TR6 Challan Date: <input type="date" value={TR6ChallanDate} onChange={(e) => setTR6ChallanDate(e.target.value)} /></label>
                </div>

                {/* Clearance Title */}
                <div className="row-title">Clearance</div>

                {/* Fourteenth Row */}
                <div className="row">
                    <label>Cleared On: <input type="date" value={clearedOn} onChange={(e) => setClearedOn(e.target.value)} /></label>
                    <label>Transport Details: <input type="text" value={transportDetails} onChange={(e) => setTransportDetails(e.target.value)} /></label>
                    <label>Incidental Expenses: <input type="text" value={incidentalExpenses} onChange={(e) => setIncidentalExpenses(e.target.value)} /></label>
                    <label>OT Details: <input type="checkbox" checked={OTDetails} onChange={(e) => setOTDetails(e.target.checked)} /></label>
                </div>

                {/* Fifteenth Row */}
                <div className="row">
                    <label>Charges: <input type="text" value={charges} onChange={(e) => setCharges(e.target.value)} /></label>
                    <label>Halting Days: <input type="text" value={haltingDays} onChange={(e) => setHaltingDays(e.target.value)} /></label>
                </div>

                {/* Sixteenth Row */}
                <div className="row">
                    <label>Freight Details: <input type="text" value={freightDetails} onChange={(e) => setFreightDetails(e.target.value)} /></label>
                    <label>Remarks: <input type="text" value={remarks} onChange={(e) => setRemarks(e.target.value)} /></label>
                </div>

                {/* Submit and Delete Buttons */}
                <div className="row">
                    <button type="submit">Submit</button>
                    <button type="button" onClick={handleDelete}>Delete</button>
                </div>
            </form>
        </div>
    );
};

export default ExportJobRegistration;
