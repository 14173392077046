import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles/FileDelete.css';

const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'file-upload';

const FileDelete = () => {
  const [refNo, setRefNo] = useState('');
  const [refNoOptions, setRefNoOptions] = useState([]);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchRefNoOptions();
    if (refNo) {
      fetchFiles(refNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refNo]);

  const fetchRefNoOptions = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'import-job/ref-numbers');
      setRefNoOptions(response.data);
    } catch (err) {
      console.error('Error fetching ref numbers:', err);
      setError('Error fetching reference numbers');
    }
  };

  const fetchFiles = async (refNo) => {
    try {
      const response = await axios.get(`${BASE_URL}/${refNo}`);
      setFiles(response.data);
    } catch (err) {
      console.error('Error fetching files:', err);
      setError('Error fetching files');
    }
  };

  const handleDelete = async (fileId) => {
    try {
      await axios.delete(`${BASE_URL}/delete/${fileId}`);
      setSuccessMessage('File deleted successfully');
      fetchFiles(refNo); 
      setTimeout(() => {
        setSuccessMessage('');
    }, 5000);
    } catch (err) {
      console.error('Error deleting file:', err);
      setError('Error deleting file');
      setTimeout(() => {
        setError('');
    }, 5000);
    }
  };

  const handleInputChange = (e) => {
    setRefNo(e.target.value);
    if (e.target.value) {
      fetchFiles(e.target.value); // Optional: Fetch files as user types
    }
  };

  return (
    <div className="file-delete-container">
    <h1 className="file-delete-title">Delete Files</h1>
    
    <div className="file-delete-form">
      <label className="file-delete-label">Reference Number</label>
      <input 
        type="text" 
        className="file-delete-input" 
        value={refNo} 
        onChange={handleInputChange} 
        list="refNoOptions" 
        placeholder="Type or select reference number"
      />
      <datalist id="refNoOptions">
        {refNoOptions.map((option) => (
          <option key={option.ref_no} value={option.ref_no} />
        ))}
      </datalist>
      
      {error && <p className="file-delete-error">{error}</p>}
      {successMessage && <p className="file-delete-success">{successMessage}</p>}
    </div>
  
    {files.length > 0 && (
      <div className="file-delete-list-container">
        <h2 className="file-delete-list-title">Uploaded Files</h2>
        <table className="file-delete-table">
          <thead>
            <tr>
              <th className="file-delete-list-header">File Name</th>
              <th className="file-delete-list-header">Actions</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file) => (
              <tr key={file.id}>
                <td className="file-delete-list-name">{file.file_name}</td>
                <td className="file-delete-list-actions">
                  <button 
                    className="file-delete-button" 
                    onClick={() => handleDelete(file.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )}
  </div>
  
  );
};

export default FileDelete;
