export const expenseType = [
    { id: 1, name: 'SEZ-1' },
    { id: 2, name: 'Airport' },
    { id: 3, name: 'seaport' },
    { id: 4, name: 'Accounts' },
    { id: 5, name: 'SEZ-2' }
];

export const transportMode = [
    { id: 1, name: 'Air' },
    { id: 2, name: 'Sea' },
    { id: 3, name: 'Road' },
    { id: 4, name: 'Courier' }
]