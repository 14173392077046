import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const ProtectedRoute = ({ component: Component, name: ComponentName }) => {
  const { user, isAuthReady } = useContext(AuthContext);

  if (!isAuthReady) {
    return null; // or a loading spinner component
  }

  if (!user) {
    return <Navigate to="/" />; // Redirect to the login page when user is not logged in
  }

  // Check if the user has a role before accessing userRoles
  const userRoles = user?.role?.routes?.split(',').map(route => route.trim()) || [];
  
  console.log(ComponentName, userRoles, userRoles.includes(ComponentName));

  if (!userRoles.includes(ComponentName)) {
    return <Navigate to="/no-access" />; // Redirect to NoAccess page
  }

  return <Component />;
};

export default ProtectedRoute;
