import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './styles/ChargesMaster.css';
import { AuthContext } from '../AuthContext';

const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'charges-master';

const ChargesMaster = () => {
  const [chargeName, setChargeName] = useState('');
  const [charges, setCharges] = useState([]);
  const [inactiveCharges, setInactiveCharges] = useState([]);
  const [editId, setEditId] = useState(null);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [viewMode, setViewMode] = useState('active');
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteType, setDeleteType] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  const { user } = useContext(AuthContext);
  const username = user.username;

  useEffect(() => {
    fetchCharges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);

  const fetchCharges = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${viewMode === 'active' ? '' : '/inactive'}`);
      viewMode === 'active' ? setCharges(response.data) : setInactiveCharges(response.data);
    } catch (err) {
      console.error('Error fetching charges:', err);
      setError('Error fetching charges');
    }
  };

  const handleSave = async () => {
    if (!chargeName) {
      setError('Charge Name is required');
      return;
    }

    try {
      if (editId) {
        await axios.put(`${BASE_URL}/${editId}`, { chargeName, updatedBy: username });
        setEditId(null);
        setSuccessMessage('Updated successfully');
      } else {
        await axios.post(BASE_URL, { chargeName, createdBy: username });
        setSuccessMessage('Saved successfully');
      }
      fetchCharges();
      resetForm();
    } catch (err) {
      console.error('Error saving charge:', err);
      setError('Error saving charge');
    }
  };

  const handleEdit = (charge) => {
    setChargeName(charge.charge_name);
    setEditId(charge.id);
  };

  const handleDelete = async () => {
    const isPermanent = deleteType === 'permanent';
    try {
      await axios.delete(`${BASE_URL}/${isPermanent ? 'permanent' : 'soft'}/${deleteId}`);
      fetchCharges();
      setShowDeleteDialog(false);
      setSuccessMessage(isPermanent ? 'Permanently deleted successfully' : 'Deleted successfully');
    } catch (err) {
      console.error(`Error ${isPermanent ? 'permanently ' : ''}deleting charge:`, err);
      setError(`Error ${isPermanent ? 'permanently ' : ''}deleting charge`);
    }
  };

  const handleBulkDelete = async () => {
    const isPermanent = viewMode === 'inactive';
    try {
      await Promise.all(selectedRecords.map(id => axios.delete(`${BASE_URL}/${isPermanent ? 'permanent' : 'soft'}/${id}`)));
      fetchCharges();
      setSuccessMessage(isPermanent ? 'Permanently deleted successfully' : 'Deleted successfully');
    } catch (err) {
      console.error(`Error ${isPermanent ? 'permanently ' : ''}deleting charges:`, err);
      setError(`Error ${isPermanent ? 'permanently ' : ''}deleting charges`);
    }
  };

  const handleSearch = () => {
    fetchCharges();
  };

  const handleReset = () => {
    setSearchTerm('');
    setFilterBy('');
    resetForm();
    fetchCharges();
    setSuccessMessage('Reset successfully');
  };

  const resetForm = () => {
    setChargeName('');
    setEditId(null);
    setError('');
  };

  const handleCheckboxChange = (id) => {
    setSelectedRecords((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((recordId) => recordId !== id) : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    const allIds = (viewMode === 'active' ? charges : inactiveCharges).map(charge => charge.id);
    setSelectedRecords(selectedRecords.length === allIds.length ? [] : allIds);
  };

  const filteredCharges = (viewMode === 'active' ? charges : inactiveCharges).filter((charge) => {
    if (!searchTerm) return true;
    switch (filterBy) {
      case 'charge_name':
        return charge.charge_name.includes(searchTerm);
      case 'created_by':
        return charge.created_by.includes(searchTerm);
      case 'updated_by':
        return charge.updated_by.includes(searchTerm);
      case 'month':
        return new Date(charge.created_at).getMonth() + 1 === parseInt(searchTerm);
      case 'year':
        return new Date(charge.created_at).getFullYear() === parseInt(searchTerm);
      default:
        return Object.values(charge).some(value => String(value).includes(searchTerm));
    }
  });

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
<div className="charges-master">
  <h1 className="charges-master-title">Charges Master</h1>
  <div className="charges-master-form">
    <label className="charges-master-label">Charge Name</label>
    <input
      type="text"
      className="charges-master-input"
      value={chargeName}
      onChange={(e) => setChargeName(e.target.value)}
    />
    <div className="charges-master-button-group">
      <button className="charges-master-button save" onClick={handleSave}>
        {editId ? 'Update' : 'Save'}
      </button>
      <button className="charges-master-button reset" onClick={handleReset}>
        Reset
      </button>
    </div>
    {error && <p className="charges-master-message error">{error}</p>}
    {successMessage && <p className="charges-master-message success">{successMessage}</p>}
  </div>
  <div className="charges-master-search-filters">
    <input
      type="text"
      className="charges-master-search-input"
      placeholder="Search"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
    <select
      className="charges-master-filter-dropdown"
      value={filterBy}
      onChange={(e) => setFilterBy(e.target.value)}
    >
      <option value="">Filter by</option>
      <option value="charge_name">Charge Name</option>
      <option value="created_by">Created By</option>
      <option value="updated_by">Updated By</option>
      <option value="month">Month</option>
      <option value="year">Year</option>
    </select>
    <button className="charges-master-button-search" onClick={handleSearch}>
      Search
    </button>
    <button className="charges-master-button reset" onClick={handleReset}>
      Reset
    </button>
  </div>
  <div className="charges-master-view-buttons">
    <button
      className={`charges-master-view-button ${viewMode === 'active' ? 'active' : ''}`}
      onClick={() => setViewMode('active')}
      style={{ backgroundColor: viewMode === 'active' ? 'green' : 'initial' }}
    >
      View Active
    </button>
    <button
      className={`charges-master-view-button ${viewMode === 'inactive' ? 'inactive' : ''}`}
      onClick={() => setViewMode('inactive')}
      style={{ backgroundColor: viewMode === 'inactive' ? 'red' : 'initial' }}
    >
      View Deleted
    </button>
  </div>
  {filteredCharges.length > 0 ? (
    <>
      <table className="charges-master-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                className="charges-master-select-all-checkbox"
                checked={selectedRecords.length === filteredCharges.length}
                onChange={handleSelectAll}
              />
            </th>
            <th>ID</th>
            <th>Charge Name</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Created By</th>
            <th>Updated By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredCharges.map((charge) => (
            <tr key={charge.id}>
              <td>
                <input
                  type="checkbox"
                  className="charges-master-record-checkbox"
                  checked={selectedRecords.includes(charge.id)}
                  onChange={() => handleCheckboxChange(charge.id)}
                />
              </td>
              <td>{charge.id}</td>
              <td>{charge.charge_name}</td>
              <td>{formatTimestamp(charge.created_at)}</td>
              <td>{formatTimestamp(charge.updated_at)}</td>
              <td>{charge.created_by}</td>
              <td>{charge.updated_by}</td>
              <td>
                {viewMode === 'active' ? (
                  <>
                    <button className="charges-master-button edit" onClick={() => handleEdit(charge)}>
                      Edit
                    </button>
                    <button
                      className="charges-master-button delete"
                      onClick={() => {
                        setShowDeleteDialog(true);
                        setDeleteType('soft');
                        setDeleteId(charge.id);
                      }}
                    >
                      Delete
                    </button>
                  </>
                ) : (
                  <button
                    className="charges-master-button delete-permanent"
                    onClick={() => {
                      setShowDeleteDialog(true);
                      setDeleteType('permanent');
                      setDeleteId(charge.id);
                    }}
                  >
                    Delete Permanently
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedRecords.length > 0 && (
        <button className="charges-master-button bulk-delete" onClick={handleBulkDelete}>
          Delete Selected
        </button>
      )}
    </>
  ) : (
    <p className="charges-master-no-records-message">No charges found</p>
  )}
  {showDeleteDialog && (
    <div className="charges-master-dialog">
      <div className="charges-master-dialog-content">
        <p className="charges-master-dialog-text">
          Are you sure you want to {deleteType === 'permanent' ? 'permanently ' : ''}delete this charge?
        </p>
        <button className="charges-master-button confirm" onClick={handleDelete}>
          Yes
        </button>
        <button className="charges-master-button cancel" onClick={() => setShowDeleteDialog(false)}>
          No
        </button>
      </div>
    </div>
  )}
</div>

  );
};

export default ChargesMaster;
