import React, { useState, useEffect } from 'react';
import './styles/RateMaster.css';
import axios from 'axios';

const RateMaster = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [invoiceHeads, setInvoiceHeads] = useState([]);
  const [rates, setRates] = useState({});

  useEffect(() => {
    axios.get(process.env.REACT_APP_BACKEND_URL + 'client-master')
      .then(response => setClients(response.data))
      .catch(error => console.error('Error fetching clients:', error));
  }, []);

  useEffect(() => {
    if (selectedClient) {
      // Fetch invoice heads when client is selected
      axios.get(process.env.REACT_APP_BACKEND_URL + 'invoice-head')
        .then(response => setInvoiceHeads(response.data))
        .catch(error => console.error('Error fetching invoice heads:', error));
    }
  }, [selectedClient]);

  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
    setRates({}); // Reset rates on client change
  };

  const handleRateChange = (invoiceHeadId, column, value) => {
    setRates(prevRates => ({
      ...prevRates,
      [invoiceHeadId]: {
        ...prevRates[invoiceHeadId],
        [column]: value,
      }
    }));
  };

  const handleSave = () => {
    const filledRates = {};

    invoiceHeads.forEach((head) => {
      filledRates[head.id] = {
        'I-20': rates[head.id]?.['I-20'] || '0',
        'I-40': rates[head.id]?.['I-40'] || '0',
        'I-LCL': rates[head.id]?.['I-LCL'] || '0',
        'I-AIR': rates[head.id]?.['I-AIR'] || '0',
        'DTA': rates[head.id]?.['DTA'] || '0',
        'I-TRANSFER': rates[head.id]?.['I-TRANSFER'] || '0',
        'I-COURIER': rates[head.id]?.['I-COURIER'] || '0',
        'E-20': rates[head.id]?.['E-20'] || '0',
        'E-40': rates[head.id]?.['E-40'] || '0',
        'E-LCL': rates[head.id]?.['E-LCL'] || '0',
        'E-AIR': rates[head.id]?.['E-AIR'] || '0',
        'BOE': rates[head.id]?.['BOE'] || '0',
        'E-TRANSFER': rates[head.id]?.['E-TRANSFER'] || '0',
        'E-COURIER': rates[head.id]?.['E-COURIER'] || '0',
        'TAX': rates[head.id]?.['TAX'] || false,
      };
    });

    axios.post(process.env.REACT_APP_BACKEND_URL + 'rate-master', { client: selectedClient, rates: filledRates })
      .then(() => {
        alert('Rates saved successfully!');
      })
      .catch(error => console.error('Error saving rates:', error));
  };

  const handleCancel = () => {
    setRates({});
  };

  return (
    <div className="bg-white w-[100%] h-[100vh] px-4 overflow-x-auto">
      <h2 className="rate-master-title text-3xl">Rate Master</h2>
      <div className="rate-master-client-selection w-[300px] mx-auto">
        <label className="rate-master-label">Client Name: </label>
        <select
          className="rate-master-select"
          value={selectedClient}
          onChange={handleClientChange}
        >
          <option value="">Select Client</option>
          {clients.map(client => (
            <option key={client.id} value={client.id}>
              {client.client_name}
            </option>
          ))}
        </select>
      </div>

      {selectedClient && invoiceHeads.length > 0 && (
        <table className="table table-xs">
          <thead className='text-sm w-[400px]'>
            <tr>
              <th>Invoice Head</th>
              <th>I-20</th>
              <th>I-40</th>
              <th>I-LCL</th>
              <th>I-AIR</th>
              <th>DTA</th>
              <th>I-TRANSFER</th>
              <th>I-COURIER</th>
              <th>E-20</th>
              <th>E-40</th>
              <th>E-LCL</th>
              <th>E-AIR</th>
              <th>BOE</th>
              <th>E-TRANSFER</th>
              <th>E-COURIER</th>
              <th>Tax</th>
            </tr>
          </thead>
          <tbody className='border border-red-900 overflow-x-hidden'>
            {invoiceHeads.map((head) => (
              <tr key={head.id} className='border border-gray-200'>
                <td className='text-xs py-4 font-bold text-gray-400'>{head.invoice_head_name}</td>
                {['I-20', 'I-40', 'I-LCL', 'I-AIR', 'DTA', 'I-TRANSFER', 'I-COURIER', 'E-20', 'E-40', 'E-LCL', 'E-AIR', 'BOE', 'E-TRANSFER', 'E-COURIER'].map((col) => (
                  <td key={col}>
                    <input
                      type="text"
                      className='border border-gray-200 py-1 px-2'
                      value={rates[head.id]?.[col] || ''}
                      onChange={(e) => handleRateChange(head.id, col, e.target.value)}
                    />
                  </td>
                ))}
                <td>
                  <input
                    type="checkbox"
                    checked={rates[head.id]?.TAX || false}
                    onChange={(e) => handleRateChange(head.id, 'TAX', e.target.checked)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className="rate-master-buttons w-[300px] mx-auto">
        <button className="rate-master-button save" onClick={handleSave}>Save</button>
        <button className="rate-master-button cancel" onClick={handleCancel}>Cancel</button>
      </div>
    </div>

  );
};

export default RateMaster;
