import React, { useContext } from 'react';
import './styles/Dashboard.css';
import { AuthContext } from '../AuthContext';

function Dashboard() {
  const { user } = useContext(AuthContext);
  const username = user.username;
  
  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        Welcome to the Dashboard {username}!
      </header>
    </div>
  );
}

export default Dashboard;
