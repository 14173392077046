import React, { useState } from 'react';
import './styles/Search.css';

const Search = () => {
  const [refNumber, setRefNumber] = useState('');
  const [containerNumber, setContainerNumber] = useState('');
  const [mawbMblNumber, setMawbMblNumber] = useState('');
  const [party, setParty] = useState('');
  const [billNumber, setBillNumber] = useState('');
  const [year, setYear] = useState('');

  const handleSearchClick = () => {
    // Handle search button click logic here
    console.log('Searching...');
  };

  const handleClearClick = () => {
    // Handle clear button click logic here
    setRefNumber('');
    setContainerNumber('');
    setMawbMblNumber('');
    setParty('');
    setBillNumber('');
    setYear('');
  };

  const handleExportExcelClick = () => {
    // Handle export to Excel logic here
    console.log('Exporting to Excel...');
  };

  const handleExportPdfClick = () => {
    // Handle export to PDF logic here
    console.log('Exporting to PDF...');
  };

  return (
    <div className="search">
      <h1>Search</h1>
      <form className="search-form">
        <div className="search-form-row">
          <div className="search-form-group">
            <label>REF#:</label>
            <input
              type="text"
              value={refNumber}
              onChange={(e) => setRefNumber(e.target.value)}
            />
          </div>
          <div className="search-form-group">
            <label>Container#:</label>
            <input
              type="text"
              value={containerNumber}
              onChange={(e) => setContainerNumber(e.target.value)}
            />
          </div>
          <div className="search-form-group">
            <label>M AWB/MBL#:</label>
            <input
              type="text"
              value={mawbMblNumber}
              onChange={(e) => setMawbMblNumber(e.target.value)}
            />
          </div>
        </div>
        <div className="search-form-row">
          <div className="search-form-group">
            <label>Party:</label>
            <input
              type="text"
              value={party}
              onChange={(e) => setParty(e.target.value)}
            />
          </div>
          <div className="search-form-group">
            <label>Bill#:</label>
            <input
              type="text"
              value={billNumber}
              onChange={(e) => setBillNumber(e.target.value)}
            />
          </div>
          <div className="search-form-group">
            <label>Year:</label>
            <select
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="">Select Year</option>
              <option value="2021-2022">2021-2022</option>
              <option value="2022-2023">2022-2023</option>
              <option value="2023-2024">2023-2024</option>
            </select>
          </div>
        </div>
        <div className="search-button-group">
          <button type="button" onClick={handleSearchClick}>Search</button>
          <button type="button" onClick={handleClearClick}>Clear</button>
        </div>
        <div className="search-export-group">
          <button type="button" onClick={handleExportExcelClick}>Export to Excel</button>
          <button type="button" onClick={handleExportPdfClick}>Export to PDF</button>
        </div>
      </form>
    </div>
  );
};

export default Search;
